<div class="company-logo left-panel">
  <img class="company-logo-background" src="/assets/images/temp/rainbowcare_logo.png">
  <div class="top-heading-btn hide-heading" *ngIf="clientDisplayName" translate>
    {{clientDisplayName}}
  </div>
  <div class="top-heading-btn hide-heading" *ngIf="!clientDisplayName" translate>
    Harris Center
  </div>
</div>
<div class="right-panel">
  <div class="app-header ui-g-1 ui-md-1 ui-lg-1 ui-g-nopad">
    <div class="top-bar-item">
      <a (click)="onSideMenuClick($event)" class="hamburger-menu">
        <i class="fa fa-bars"></i>
      </a>
    </div>
  </div>
  <div class="ui-g-11 ui-md-11 ui-lg-11 ui-g-nopad profile-panel">
    <div class="nav-right-menu">
      <!-- <div class="top-bar-item book-appt-button mr-30" *ngIf="adminUser && disablePendingApproval">
        <button pButton class="pending-btn" type="button" [label]="pendingCount"
          (click)="gotoAppointments();"></button>
      </div> -->
      <!-- <div class="top-bar-item book-appt-button" *ngIf="showDualBookingBtn && saveAppointmentDraft === 'true'">
        <button pButton class="book-btn" type="button" [label]="'label_book_dual_appointment' | translate"
          (click)="gotoBookDualAppt();"></button>
      </div> -->
      <!-- <div class="top-bar-item book-appt-button" appAccessProvider accessCode="DISPATCH_ADD_DISPATCH"> -->
      <div class="top-bar-item edit-shift-btn" *ngIf="shiftManagerNotesData">
        <img src='/assets/images/temp/editbtn-icon.svg' *ngIf="managerNotesAddEditAccess"  class="edit-pos" title="Edit on call shift manager details" (click)="showShiftDetailsPopup('edit')">
        <img src="/assets/images/temp/delete-icon.svg" *ngIf="managerNotesDeleteAccess" title="Delete" class="edit-pos" (click)="deleteShiftManager()">
        <!-- <div class="shift-txt-break" *ngIf="managerNotesViewAccess"  [innerHTML]="shiftManagerEllipsisText" tooltipStyleClass="shift-tooltip" [escape]="false"  pTooltip="<h5>On Call Shift Manager Details</h5> {{shiftManagerNotesData}}"  tooltipPosition="right" placeholder="Right"></div> -->
        <div class="shift-txt-break" *ngIf="managerNotesViewAccess"  [innerHTML]="shiftManagerEllipsisText" (click)="tooltipShow()"></div>
      </div>
        <button pButton *ngIf="!shiftManagerNotesData && managerNotesAddEditAccess"  class="top-bar-item shift-manager-btn" type="button" [label]="'label_add_call_manager_details' | translate"
        (click)="showShiftDetailsPopup('add')"></button>
      <!-- <div class="top-bar-item book-appt-button" *ngIf="showDispatchBtn && !rabbitMQEnabled">
        <button pButton class="book-btn" type="button" [label]="'label_new_dispatch_request' | translate"
          (click)="gotoAddDispatchRequest();"></button>
      </div>  -->
      <div class="top-bar-item book-appt-button" *ngIf="showContactBtn">
        <!-- <button pButton class="book-btn" type="button" [label]="'label_new_dispatch_request' | translate" -->
        <button pButton class="create-button" type="button" [label]="'Look-up Client' | translate" (click)="showDialog()"></button>
      </div>
      <div class="top-bar-item book-appt-button" *ngIf="showContactBtn">
        <!-- <button pButton class="book-btn" type="button" [label]="'label_new_dispatch_request' | translate" -->
        <button pButton class="book-btn" type="button" [label]="'New Contact Form' | translate"

          (click)="gotoAddContactForm();"></button>
      </div>
      <div class="top-bar-item notifications-bell" (click)="getNotificationListAndToggle($event)">
        <a class="nav-notifications-icon">
          <i class="fa fa-bell-o" aria-hidden="true"></i>
          <div class="animated-new-notification-count" *ngIf="unReadNotificatonsCount">
            <span *ngIf="unReadNotificatonsCount > 0"></span>
          </div>
        </a>
      </div>

      <div class="profile-menu-button top-bar-item">
        <p-menu #menu popup="popup" [model]="items"></p-menu>
        <a (click)="menu.toggle($event)">
          <img class="user-photo" src="{{profileUrl}}" onerror="src='/assets/images/temp/default_profile.png'">
          <label class="user-name">{{userFullName}}</label>
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="hasShowNotificationBar" [style]="{width: '400px'}" position="right">
  <div class="notification-container">
    <div class="header">
      <h3 class="header-heading notify-header">{{notificationLabel}}</h3>
    </div>
    <div class="content">
      <app-notification-mg1 [notifications]="notifications" [showMoreOff]="showMoreOff" [notificationCount]="notificationCount" (sideBarCloseEvent)="onClickOnNotification()"
        (loadMoreNotification)="loadMoreNotification()">
      </app-notification-mg1>
    </div>
  </div>
</p-sidebar>

<p-dialog [blockScroll]="true" modal="true" [showHeader]="false" styleClass="add-terms-popup" [style]="{width: '35%'}"
  [(visible)]="changePasswordPopup" [closable]="false">
  <app-change-password [(changePasswordPopup)]="changePasswordPopup"
    (isShowChangePassword)="onCancelChangePassword($event)"></app-change-password>
</p-dialog>

<p-dialog [blockScroll]="true" modal="false" [showHeader]="false" styleClass="edit-profile-popup profile-screen"
  [style]="{width: '35%'}" responsive="true" [(visible)]="editProfilePopup">
  <app-edit-profile [(editProfilePopup)]="editProfilePopup" (showProfilePopup)="onCancelEditProfile($event)">
  </app-edit-profile>
</p-dialog>


<p-dialog [blockScroll]="true" modal="false" id="confirm-dialog-session" [showHeader]="false" styleClass="edit-profile-popup"
  [style]="{width: '500px'}" responsive="true" [(visible)]="headerConfirmDialog">
  <div class="ui-g form-container">
    <div class="ui-g-12 header-dialog-heading">
      <p translate>label_confirmation</p>
    </div>
    <div class="ui-g-12 header-dialog-content">
      {{headerMessage}}
    </div>
    <div class="ui-g-12 ui-g-nopad form-buttons">
      <button class="negative-button" pButton type="button" icon="fa fa-close" label="No"
        (click)="logout()"></button>
        <!-- <a href="https://harrisdms-dev-api.rainbow.health/api/rcdms003/saml2/ssologout">logOut page</a> -->
      <button pButton type="button" label="Yes" icon="fa fa-check" (click)="refreshSession()"></button>
    </div>
  </div>
</p-dialog>

<p-dialog [blockScroll]="true" modal="true" [showHeader]="false" position="center" responsive="true"
  [style]="{width: '450px'}"  [(visible)]="shiftDetailsPopup">
  <div class="ui-g form-container">
      <div class="ui-g-12">
        <h3>{{ shiftManagerpopupHeading | translate}}</h3>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
        
        <div class="ui-g">
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
                <div class="form-label">
                  <label translate>label_shift_manager_details</label>
                </div>
                <div class="form-control-c" *ngIf="shiftDetailsPopup">
                    <p-editor  [(ngModel)]="shiftManagerNotes" (onTextChange)="shiftDetailsValidation($event)" [style]="{'height':'250px'}">
                      <p-header>
                        <span class="ql-format-group">
                          <span class="ql-formats">
                            <span class="ql-formats">
                              <button class="ql-bold"></button>
                              <button class="ql-italic"></button>
                              <button class="ql-underline"></button>
                              <button class="ql-strike"></button>
                            </span>
                            <span class="ql-formats">
                              <select class="ql-color"></select>
                              <select class="ql-background"></select>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-script" value="sub"></button>
                              <button class="ql-script" value="super"></button>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-header" value="1"></button>
                              <button class="ql-header" value="2"></button>
                              <button class="ql-blockquote"></button>
                              <button class="ql-code-block"></button>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-list" value="ordered"></button>
                              <button class="ql-list" value="bullet"></button>
                              <button class="ql-indent" value="-1"></button>
                              <button class="ql-indent" value="+1"></button>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-direction" value="rtl"></button>
                              <select class="ql-align"></select>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-link"></button>
                            </span>
                          </span>
                        </span>
                      </p-header>
                   </p-editor>
                </div>
     
            </div>
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12 txt-right">
                <button class="cancel-btn mr-20" pButton type="button"  [label]="'label_cancel' | translate" (click)="hideShiftDetailsPopup()"></button>
                <button pButton class="save-btn" type="button" [disabled]="!shiftManagerNotes || shiftManagerValidation"  [label]="'label_save' | translate" (click)="saveShiftManagerNotes()"></button>
            
            </div>
        </div>
       
     </div>
   </div>   
</p-dialog>

<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" key="shiftManagerConfirmation" [acceptLabel]="labelYes" [rejectLabel]="labelNo">
</p-confirmDialog>


<p-dialog [blockScroll]="true" modal="true" [showHeader]="false" position="center" responsive="true"
  [style]="{width: '450px'}"  [(visible)]="shiftDetailsTooltipPopup">
  <div class="ui-g form-container">
      <div class="ui-g-12 tooltip-popup-header">
        <h4 translate>label_on_call_shift_heading</h4>
        <img src="/assets/images/temp/close1.png" class="cp" (click)="tooltipHide()">
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad tooltip-scroll ">
        <div class="ui-g">
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
                <div class="form-control-c">
                  <div [innerHTML]="shiftManagerNotesData"></div>
                </div>
            </div>
        </div>
     </div>
   </div>   
</p-dialog>

<!-- <app-editable-contact-form [message]="dialogFirstName"></app-editable-contact-form> -->
