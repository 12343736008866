import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  private sharedData = new BehaviorSubject<any>(null);
  sharedData$ = this.sharedData.asObservable();

  setData(data: any) {
    this.sharedData.next(data);
  }

  getData() {
    return this.sharedData.getValue();
  }
}

