import { Injectable } from '@angular/core';
import { RestApiService } from 'app/modules/shared/services/rest-api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {
  referalData = {};
  private customSubject = new Subject<any>();
  customObservable = this.customSubject.asObservable();

  private storedFirstName = new BehaviorSubject<string>('Initial data');
  data$ = this.storedFirstName.asObservable();

  dataLookupEhr = new BehaviorSubject<any>(null);
  dataLookupObservable = this.dataLookupEhr.asObservable();
 
isContactFormPage = true;
  constructor(private restApiService: RestApiService) { }

  getContactFormId(): Observable<any> {
    return this.restApiService.get('', '/secure/contact-form/create-id', 'page-center');
  }

  setContactForm(id , data): Observable<any> {
    return this.restApiService.put('', '/secure/contact-form/' + id, data , 'page-center');
  }

  getDetailsByContactNumber(searchNumber, selectedDate): Observable<any> {
    return this.restApiService.get('', '/secure/passengers/search/list?phoneNum=' + searchNumber + '&contactFormDate=' + selectedDate + '&limit=10&offset=0', 'page-center');
  }

  setFollowUp(id, data): Observable<any> {
    return this.restApiService.post('', '/secure/contact-form/follow-up/' + id, data , 'page-center');
  }

  getFollowUp(id): Observable<any> {
    return this.restApiService.get('', '/secure/follow-up/' + id, 'page-center');
  }

  getAssinedFollowUp(): Observable<any> {
    return this.restApiService.get('', '/secure/users/list', 'page-center');
  }

  getReferral(serviceCategories, search, zipCode, contactFormId, limit, offset): Observable<any> {
    return this.restApiService.get('', '/secure/referral-resources/grid/list' + '?resourceTypeId=' + serviceCategories + '&searchText=' +
    search  + '&zipcode=' + zipCode + '&contactFormId=' + contactFormId + '&limit=' + limit + '&offset=' + offset + '&status=' + 'active',   'page-center');
  }

  getContactFormDetails(contactId): Observable<any> {
    return this.restApiService.get('', '/secure/contact-form?contactFormId=' + contactId, 'page-center');
  }

  getReferralDetails(contactFormId): Observable<any> {
    return this.restApiService.get('', '/secure/referrals/grid/list' + '?contactFormId=' + contactFormId, 'page-center');
  }

  sendReferrals(data): Observable<any> {
    return this.restApiService.post('', '/secure/referrals', data , 'page-center');
  }

  dispatchContactForm(dispatchId,selectedDispatchTypeID): Observable<any> {
    return this.restApiService.post('', '/secure/contact-form/dispatch/' + dispatchId + '?dispatchTypeId=' + selectedDispatchTypeID , 'page-center');
  }

  dispatchContactFormWithParams(dispatchId, selectedDispatchTypeID): Observable<any> {
    return this.restApiService.post('', '/secure/contact-form/dispatch/' + dispatchId + '?dispatchTypeId=' + selectedDispatchTypeID , 'page-center');
  }

  updateContactForm(contactId, data): Observable<any> {
    return this.restApiService.put('', '/secure/contact-form/' + contactId, data, 'page-center');
  }

  removeReferral(referralId ): Observable<any> {
    return this.restApiService.put('', '/secure/referrals/delete/' + referralId , 'page-center');
  }

  detailReferral(referralId ): Observable<any> {
    return this.restApiService.get('', '/secure/referrals/' + referralId , 'page-center');
  }

  getCcdCounsellor(data): Observable<any> {
    return this.restApiService.put('', '/secure/users-list' , data, 'page-center');
  }

  getCcdCounsellorPaginationList(): Observable<any> {
    return this.restApiService.get('', '/secure/follow-up/users/list?limit=500&offset=0', 'page-center');
  }

  getContactFormCount(userId, selectedDate, selectedPhoneNum): Observable<any> {
    // return this.restApiService.get('', '/secure/contact-form/users/' + userId + '?contactFormDate=' + selectedDate ,'page-center');
    return this.restApiService.get('', '/secure/contact-form/users/0?contactFormDate=' + selectedDate + '&phoneNum=' + selectedPhoneNum , 'page-center');
  }

  getClientListbyMRN(searchString, selectedDate): Observable<any> {
    return this.restApiService.get('', '/secure/passengers/search/list?memberId=' + searchString +  '&contactFormDate=' + selectedDate + '&limit=10&offset=0', 'page-center');
  }

  downloadFile(fileId, filename) {
    return this.restApiService.pdf('', '/secure/file/' + fileId , filename);
  }

  shareviaEmail(contactId, data): Observable<any> {
    return this.restApiService.put('', `/secure/contact-form/${contactId}/share` , data , 'page-center');
  }

  contactSaveFilter(data): Observable<any> {
    return this.restApiService.post('', '/secure/user-filters', data, 'page-center');
  }

  contactGetFilter(userID, type): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userID + '?filterType=' + type, 'page-center');
  }

  contactResetFilter(userId, type): Observable<any> {
    return this.restApiService.delete('', '/secure/user-filters/' + userId + '?filterType=' + type  , 'page-center');
  }

  autoSaveForm(contactFormId, data): Observable<any> {
    return this.restApiService.post('', '/secure/contact-form/' + contactFormId + '/log', data, 'page-center');
  }

  contactformAutoSaveCall() {
    this.customSubject.next();
  }

  getDispatchTypes(type): Observable<any> {
    return this.restApiService.get('', '/secure/service-types?code=' + type , 'page-center');
  }

  getContactFormAccessUser(): Observable<any> {
    return this.restApiService.get('', '/secure/contact-form/cfAccessUser', 'page-center');
  }

  updateData(newData: string) {
    this.storedFirstName.next(newData);
    console.log("newData",newData);
  }

  searchLookupDmsData(data): Observable<any>{
    return this.restApiService.post('', '/secure/users/lookup', data, 'page-center');
  }

 /*  getClientUpdatedData(contactFormId): Observable<any> {
    return this.restApiService.get('', '/secure/contact-form/client-log/', + contactFormId, 'page-center');
   
  } */
    getClientUpdatedData(contactFormId): Observable<any> {
      return this.restApiService.get('', '/secure/contact-form/client-log/' + contactFormId, 'page-center');
    }
}
