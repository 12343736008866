<div class="slot-card-wrapper" [ngClass]="borderClass">
  <div class="team-heading">
    <div class="text team-name" *ngIf="!istrim">{{teamName}}</div>
    <div class="text team-name" pTooltip="{{teamDetails?.teamName}}" tooltipStyleClass="tooltip-custom"
    *ngIf="istrim">{{teamName}}</div>
    <div class="team-members">
      <img class="profile-icon" src="/assets/images/temp/profile-icon.svg">
      <span class="text team-name"> {{teamDetails?.applicationCount}} </span>
    </div>
  </div>
  <div class="team-slot">
    <span class="text">{{teamDetails?.startDateTimeStr}} - {{teamDetails?.endDateTimeStr}}</span>
  </div>
</div>