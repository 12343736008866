import { Injectable } from '@angular/core';
import { RestApiService } from 'app/modules/shared/services/rest-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalConfigService {

  constructor(private restApiService: RestApiService) { }

  getGlobalConfigList(): Observable<any> {
    return this.restApiService.get('', '/secure/global-configurations', 'page-center');
  }

  getDutyMasters() : Observable<any> {
    return this.restApiService.get('', '/secure/duty-masters', 'page-center');
  }

  getProvider(): Observable<any> {
    return this.restApiService.get('', '/secure/providers/data/all', 'page-center');
  }

  getAllTeamsList(): Observable<any> {
    return this.restApiService.get('', '/secure/teams/data/all?providerIds=', 'page-center');
  }

  saveAllGlobalConfig(data): Observable<any> {
    return this.restApiService.post('', '/secure/global-configurations', data, 'page-cnter');
  }
}
