<div id="create-form">
  <div class="ui-g page-header-c" style="margin-top: 8px;">
    <div class="ui-g-12 ui-md-6 ui-lg-6">
      <h1 class="page-title header-margin" translate>
        label_look_up
        <span class="tooltip-icon">
          <img src="assets/images/temp/info.png" width="15px" height="15px" class="tooltip-icon"
            tooltipPosition="bottom" style="cursor: pointer;" alt="info" />
          <div class="tooltip-data">
            <div class="ul-style">
              <b>
                <span translate>
                  label_search_criteria
                </span>
              </b>
              <ul>
                <li class="li-margin li-style" translate>label_criteria_mrn</li>
                <li class="li-margin li-style" translate>label_criteria_name_birth_date</li>
                <li class="li-margin li-style" translate>label_criteria_name_gender_email</li>
              </ul>
            </div>
          </div>
        </span>
      </h1>
    </div>
    <div class="ui-g-12 ui-md-6 ui-lg-6">
      <button class="close-button" (click)="close()">X</button>
    </div>
  </div>
  <form [formGroup]="ngForm">
    <div class="ui-lg-12 ui-g-12 ui-md-12">
      <div class="ui-lg-4 ui-g-4 ui-md-4" class="div-margin">
        <div class="radio-button-input radio-bottom-margin">
          <p-radioButton type="radio" id="html" name="options" value="SSN" formControlName="radioValue"
            class="radio-button" (onClick)="onSelectionChange()"></p-radioButton>
          <label translate>label_ssn</label>

        </div>
        <div *ngIf="ngForm.get('radioValue').value === 'SSN'" class="radio-margin-left">
          <div style="margin-top: 16px">
            <label translate>label_ssn_no</label>
            <label class="asterisk">*</label>
          </div>

          <input type="text" formControlName="ssn" class="input-radio-field" value="ssn"
            [placeholder]="'Enter SSN Number' | translate" />
          <div class="form-errors"
            *ngIf=" (submitted && ngForm.controls.ssn.errors) || (ngForm.controls.ssn.dirty || ngForm.controls.ssn.touched)">
            <div *ngIf="!editDispatchFlag && ngForm.controls.ssn.errors && ngForm.controls.ssn.errors.required"
              translate>
              cf_validation_ssn_error
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui-lg-12 ui-g-12 ui-md-12">
      <div class="ui-lg-4 ui-g-4 ui-md-4" class="div-margin">
        <div class="radio-button-input radio-bottom-margin">
          <p-radioButton type="radio" id="html" name="options" value="HCMRN" formControlName="radioValue"
            class="radio-button" (click)="onSelectionChange()"></p-radioButton>
          <label translate>label_Mrn</label>
        </div>
        <div *ngIf="ngForm.get('radioValue').value === 'HCMRN'" class="radio-margin-left">
          <div style="margin-top: 16px">
            <label translate>label_Mrn_No</label>
            <label class="asterisk">*</label>
          </div>

          <input type="text" formControlName="mrn" class="input-radio-field"
            [placeholder]="'Enter MRN Number' | translate" />
          <div class="form-errors"
            *ngIf=" (submitted && ngForm.controls.mrn.errors) || (ngForm.controls.mrn.dirty || ngForm.controls.mrn.touched)">
            <div *ngIf="!editDispatchFlag && ngForm.controls.mrn.errors && ngForm.controls.mrn.errors.required"
              translate>
              cf_validation_mrn_error
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui-lg-12 ui-g-12 ui-md-12">
      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero ">
        <div class="radio-button-input">
          <p-radioButton type="radio" id="html" name="options" value="" class="radio-button"
            formControlName="radioValue" (onClick)="onSelectionChange()"></p-radioButton>
          <label translate>label_patient_demo</label>
        </div>

      </div>
    </div>

    <div class="ui-lg-12 ui-g-12 ui-md-12 first-container container-margin">

      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero" *ngIf="ngForm.get('radioValue').value === ''">
        <label translate>label_firstname</label>
        <label class="asterisk">*</label>
        <input autocomplete="off" type="text" formControlName="firstName" class="input-form-field"
          style="margin-top: 4px;" [placeholder]="'Enter First Name' | translate" pInputText />
        <div class="form-errors"
          *ngIf=" (submitted && ngForm.controls.firstName.errors) || (ngForm.controls.firstName.dirty || ngForm.controls.firstName.touched)">
          <div
            *ngIf="!editDispatchFlag && ngForm.controls.firstName.errors && ngForm.controls.firstName.errors.required"
            translate>
            cf_validation_first_name_error
          </div>
          <div *ngIf="ngForm.controls.firstName.errors && ngForm.controls.firstName.errors.minlength" translate>
            cf_validation_min_first_name
          </div>
          <div *ngIf="ngForm.controls.firstName.errors && ngForm.controls.firstName.errors.maxlength" translate>
            cf_validation_max_first_name
          </div>
          <div *ngIf="ngForm.controls.firstName.errors && ngForm.controls.firstName.errors.pattern" translate>
            name_pattern
          </div>
        </div>
      </div>
      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero">
        <div *ngIf="ngForm.get('radioValue').value === ''">
          <label translate>label_lastname</label>
          <label class="asterisk">*</label>
          <input type="text" formControlName="lastName" class="input-form-field top-margin"
            [placeholder]="'Enter Last Name' | translate" />
          <div class="form-errors"
            *ngIf=" (submitted && ngForm.controls.lastName.errors) || (ngForm.controls.lastName.dirty || ngForm.controls.lastName.touched)">
            <div
              *ngIf="!editDispatchFlag && ngForm.controls.lastName.errors && ngForm.controls.lastName.errors.required"
              translate>
              cf_validation_req_last_name
            </div>
            <div *ngIf="ngForm.controls.lastName.errors && ngForm.controls.lastName.errors.minlength" translate>
              cf_validation_min_last_name
            </div>
            <div *ngIf="ngForm.controls.lastName.errors && ngForm.controls.lastName.errors.maxlength" translate>
              cf_validation_max_last_name
            </div>
            <div *ngIf="ngForm.controls.lastName.errors && ngForm.controls.lastName.errors.pattern" translate>
              name_pattern
            </div>
          </div>
        </div>
      </div>
      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero">
        <div *ngIf="ngForm.get('radioValue').value === ''">
          <label translate>label_gender</label>
          <p-dropdown formControlName="gender" [options]="genderList" placeholder="Select a gender"
            [style]="{ width: '100%', height: '37px' }"></p-dropdown>
        </div>
      </div>
    </div>
    <div class="ui-lg-12 ui-g-12 ui-md-12 first-container container-margin">
      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero " *ngIf="ngForm.get('radioValue').value === ''">
        <div>
          <label translate>label_date_of_birth</label>
        </div>
        <div>
          <p-calendar id="date" formControlName="dateOfBirth" [showIcon]="true" [maxDate]="maxDate"
            [defaultDate]="defaultDate" dateFormat="{{dateFormat}}" [monthNavigator]="true" [yearNavigator]="true"
            [yearRange]="issueDateRange" [placeholder]="'Enter date of birth' | translate"></p-calendar>
        </div>
      </div>
      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero" *ngIf="ngForm.get('radioValue').value === ''">
        <div class="form-label">
          <label for="contactNumber" translate>label_phone_number</label>
        </div>
        <div class="left">
          <p-dropdown class="countory-code" [disabled]="true" [options]="countryCodeList"
            [style]="{ width: '100%', height: '37px' }" [filter]="true">
          </p-dropdown>
        </div>
        <div class="right">
          <input type="text" formControlName="contactNumber" class="input-form-field top-margin" [maxlength]="12"
            (keyup)="onMobileChange($event)" [placeholder]="'Enter phone number' | translate" appPhoneMask pInputText />
          <div class="form-errors" *ngIf="
            ngForm.controls.contactNumber.errors &&
            (ngForm.controls.contactNumber.dirty || ngForm.controls.contactNumber.touched)
          ">
            <div [hidden]="!ngForm.controls.contactNumber.errors.minlength" translate>
              min_length_phone_number
            </div>
            <div [hidden]="!ngForm.controls.contactNumber.errors.maxlength" translate>
              min_length_phone_number
            </div>
          </div>
        </div>
      </div>
      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero" *ngIf="ngForm.get('radioValue').value === ''">
        <label translate>label_email</label>
        <input type="text" formControlName="email" class="input-form-field top-margin"
          [placeholder]="'Enter email' | translate" />
        <div class="form-errors" *ngIf="
            ngForm.controls.email.errors &&
            (ngForm.controls.email.dirty || ngForm.controls.email.touched)
          ">
          <div [hidden]="!ngForm.controls.email.errors.pattern" translate>
            email_pattern
          </div>
        </div>
      </div>
    </div>
    <div class="ui-lg-12 ui-g-12 ui-md-12 first-container container-margin">
      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero" *ngIf="ngForm.get('radioValue').value === ''">
        <label translate>label_address_line_one</label>
        <input type="text" formControlName="addressLineOne" class="input-form-field top-margin"
          [placeholder]="'Enter address line' | translate" />
      </div>
      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero" *ngIf="ngForm.get('radioValue').value === ''">
        <label translate>label_city</label>
        <input type="text" formControlName="city" class="input-form-field top-margin"
          [placeholder]="'Enter city' | translate" />
      </div>
      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero" *ngIf="ngForm.get('radioValue').value === ''">
        <label translate>label_state</label>
        <input type="text" formControlName="state" class="input-form-field top-margin"
          [placeholder]="'Enter state' | translate" />
      </div>
    </div>
    <div class="ui-lg-12 ui-g-12 ui-md-12 first-container container-margin">
      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero" *ngIf="ngForm.get('radioValue').value === ''">
        <label translate>label_country</label>
        <input type="text" formControlName="country" class="input-form-field top-margin"
          [placeholder]="'Enter country' | translate" />
      </div>
      <div class="ui-lg-4 ui-g-4 ui-md-4 padding-zero" *ngIf="ngForm.get('radioValue').value === ''">
        <label translate>zip_code</label>
        <input type="text" formControlName="zipCode" class="input-form-field top-margin"
          [placeholder]="'Enter zip code' | translate" />
        <div class="form-errors"
          *ngIf="ngForm.controls.zipCode.errors  && (ngForm.controls.zipCode.dirty || ngForm.controls.zipCode.touched)">
          <div [hidden]="!ngForm.controls.zipCode.errors.pattern" translate>
            Invalid Zip Code
          </div>
          <div [hidden]="!ngForm.controls.zipCode.errors.minlength" translate>
            zipcode_invalid
          </div>
          <div [hidden]="!ngForm.controls.zipCode.errors.maxlength" translate>
            zipcode_invalid
          </div>
        </div>
      </div>
    </div>

  </form>
</div>
<div class="ui-g-12 ui-md-12 ui-lg-12">
  <div class="create-button-group" style="margin-bottom: 16px;">
    <button pButton [label]="'Look-up Client in EHR'" class="button-margin" (click)="searchLookupEhr()"
      [disabled]="ngForm.invalid"></button>
    <button pButton [label]="'Look-up Client in DMS'" class="button-margin" (click)="searchLookupDms()"
      [disabled]="ngForm.invalid"></button>

    <button *ngIf="!showUpdateButton" pButton [label]="isEditContact ? ('label_update_contact_form' | translate) : ('label_create_new_contact' | translate)" class="create-button"
      [disabled]="ngForm.invalid || (ngForm.value.radioValue === 'HCMRN' || ngForm.value.radioValue === 'SSN') "
      (click)="manualAddContactForm()"></button>
  </div>
</div>
<div class="ui-g-12" class="table-top" *ngIf="showResult">
  <h1 class="page-title page-title-header" translate> label_search_result</h1>
  <p-table class="search-table" *ngIf="lookupData.length > 0" [value]="lookupData" [paginator]="true" [rows]="10"
    [responsive]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>MRN</th>
        <th>Email ID</th>
        <th>Phone Number</th>
        <th>Address</th>
        <th>Date Of Birth</th>
        <th class="action-col">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <td>{{data.firstName}}</td>
        <td>{{data.lastName}}</td>
        <td>{{data.memberId}}</td>
        <td title="{{data.email}}">{{data.email}}</td>
        <td title="{{data.phoneNum}}">{{data.phoneNum}}</td>
        <td>{{data.addressLine1}}</td>
        <td>
          <span *ngIf="data.dateOfBirth === 0"></span>
          <span *ngIf="data.dateOfBirth !== 0">{{getFormattedDate(data.dateOfBirth)}}</span>
        </td>
        <td class="action-col">
          <button pButton [label]="isEditContact ? ('label_update_contact_form' | translate) : ('label_create_new_contact' | translate)" class="create-button" type="button"
            (click)="gotoAddContactForm(data)"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div *ngIf="lookupData.length === 0" class="no-data-message" translate>
    label_no_records_found
  </div>

</div>
