import { Component, OnInit, OnDestroy, ElementRef, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UiService } from '../../services/ui.service';
import { LazyLoadEvent } from 'primeng';
import { Grid2Service } from './grid2.service';
import { Grid2Model } from './grid-2-model';
import { Table } from 'primeng/table';
import { AppSettings } from '../../app.settings';
import { Router, RouterLink } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-mi-grid-v2',
  providers: [Grid2Service],
  templateUrl: './mi-grid-2.component.html'
})

export class MiGrid2Component implements OnInit, OnDestroy {
  subscription: Subscription;
  rowData: any[] = [];
  search = '';
  @Output() rowClickedEvent = new EventEmitter<any>();
  @Output() totalCount = new EventEmitter<any>();
  @Output() actionBtnClickedEvent = new EventEmitter<any>();
  @Output() getGridReference: EventEmitter<any> = new EventEmitter();
  @Output() searchTextgrd = new EventEmitter<any>();
  @Input() url: any = '';
  @Input() bottomSearchBar: Boolean;
  @Input() isDetailScreen: Boolean;
  @Input() bottomExportButton = false;
  @Input() actionWidth = 130;
  @Input() rowSelection = 'single';
  @Input() printUrl: any = '';
  @Input() exportUrl: any = '';
  @Input() exportCSVUrl: any = '';
  @Input() columnDefs: any[] = [];
  @Input() actionButtonsDefs: any[] = [];
  @Input() pagination: any = true;
  @Input() paginationPageSize: any = 15;
  @Input() paginationStartPage: any = 0;
  @Input() defaultSortField = '';
  @Input() divClass = '';
  @Input() placeholderValue = 'Press enter to search';
  @ViewChild('dt') dt: Table;
  @Input() staticGridData = [];
  @Input() isPasteEnabled = false;
  queryParams: any = '';
  gridUrlParams: any = '';
  enableResizer = 'false';
  @Input() overlayLoadingTemplate: any = '<span class="ag-overlay-loading-center b-red">' +
    'Please wait while your rows are loading</span>';
  datasource = [];
  model: Grid2Model;
  totalRecords = 0;
  sortF = '';
  first: any = 0;
  sortO = '';
  offset = 0;
  setRowLength = 10;
  searchText = '';
  selectedRecords: any = [];
  constructor(private elm: ElementRef, private uiService: UiService, private gridService: Grid2Service, private router: Router) { }

  ngOnInit() {
    this.subscription = this.uiService.sidebarToggledEvent
      .subscribe((state: string) => {
        this.refreshList();
      });
  }

  onResize(event: any) {
    // this.refreshList();
  }

  refreshList() {
    this.fetchAndLoadData();
  }

  fetchAndLoadData() {
    const that = this;
    if (that.url) {
      this.gridService.loadGridData(that.getUrl(that.url)).subscribe(result => {
        const model = <Grid2Model>result;
        if (result !== undefined) {
          this.totalRecords = model.count;
          that.datasource = [];

          if (this.staticGridData.length > 0) {
            model.data = this.staticGridData;
          }

          model.data.forEach(e => {
            that.columnDefs.forEach(c => {
              if (c && c.cellRenderer) {
                e[c.field] = c.cellRenderer(e[c.field]);
              } else if (c && c.cellRendererHtml) {
                e[c.field] = c.cellRendererHtml(e);
              }

              // that.actionButtonsDefs.forEach(d => {
              //   if (d.show) {
              //     e[d.field] = d.show(e);
              //   }
              // });

              that.actionButtonsDefs.forEach(d => {
                if (d.show) {
                  e[d.field] = d.show(e);
                }
                if (d.title) {
                  e[d.title] = d.title(e);
                }
              });
            });
            that.datasource.push(e);
          });
        }
        this.totalCount.emit(this.totalRecords);
        this.dt.first = this.offset;
      });
    }
  }

  getUrl(url) {
    const that = this;
    let params = 'searchText=' + this.searchText + '&offset=' + this.offset + '&limit=' + this.setRowLength;
    params += '&sortColumn=';
    params += this.sortF;
    params += '&sortType=';
    params += this.sortO;
    let buildUrl = url;
    if (url.lastIndexOf('?') < 0) {
      buildUrl += '?';
    } else {
      buildUrl += '&';
    }

    if (this.url.lastIndexOf('?') < 0) {
      this.gridUrlParams = '';
    } else {
      this.gridUrlParams = this.url.substring(this.url.lastIndexOf('?'));
      this.gridUrlParams = this.gridUrlParams.replace('?', '&');
    }

    buildUrl += params;
    return buildUrl;
  }

  public onQuickFilterChanged($event, dt) {
    if ($event.key === 'Enter') {
      this.applySearch(this.search);
      dt.first = 0;
      this.searchTextgrd.emit(this.search);

    }
  }

  keyPress($event) {
    const invalidKeys = AppSettings.NOT_ALLOWED_KEYS;
    if (invalidKeys.indexOf($event.key) !== -1) {
      return false;
    }
  }

  updateUrl(url: string) {
    this.url = url;
    this.offset = 0;
    this.first = 0;
    const paramArray: any[] = this.url.split('?');
    if (paramArray.length > 1) {
      this.queryParams = paramArray[1];
    } else {
      this.queryParams = '';
    }
    this.refreshList();
  }

  updateData(url: string) {
    this.url = url;
    const paramArray: any[] = this.url.split('?');
    if (paramArray.length > 1) {
      this.queryParams = paramArray[1];
    } else {
      this.queryParams = '';
    }
    this.refreshList();
  }

  loadDatasourceLazy(event: LazyLoadEvent) {
    this.sortF = this.defaultSortField;
    this.offset = event.first;
    this.first = event.first;
    this.setRowLength = event.rows;
    if (event.sortField !== undefined) {
      this.sortF = event.sortField;
    }
    this.sortO = event.sortOrder > 0 ? 'asc' : 'desc';
    this.fetchAndLoadData();
  }

  onRowSelect(event) {
    this.rowClickedEvent.emit(event);
  }

  onRowUnselect(event) {
  }


  actionBtnClick(event, data: any, action: string) {
    event.stopPropagation();
    const obj = { row: data, class: action };
    this.actionBtnClickedEvent.emit(obj);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getSelectedRecords() {
    return this.selectedRecords;
  }

  applySearch(searchText: string) {
    this.searchText = searchText;
    this.offset = 0;
    this.first = 0;
    this.fetchAndLoadData();
  }

  exportGridExcel(event: any) {
    const that = this;
    // this.gridService.downloadExcelGridData(that.getUrl(that.exportUrl + '?' + this.searchText + '&' + this.queryParams));
    this.gridService.downloadExcelGridData(that.getUrlExport(that.exportUrl) + this.gridUrlParams);

  }

  exportGridPdf(event: any) {
    const that = this;
    this.gridService.downloadPdfGridData(that.getUrl(that.printUrl + '?' + this.searchText + '&' + this.queryParams));
  }

  /*   getTitle(value) {
      return value.replace('<br>', ', ');
    } */

  getTitle(value) {
    if (value.title) {
      return value.title;
    } else if (value.innerHTML) {
      return value.innerHTML.replace('<br>', ', ');
    } else {
      return value;
    }
  }

  getDropdownColor(rowData) {

    if (this.router.url === '/contact-form') {
      if (rowData.contactFormStatus === 420) {
        return 'contact-form';
      } else if (rowData.contactFormStatus === 410) {
        return 'drafts';
      } else if (rowData.contactFormStatus === 430) {
        return 'dispatch-forms';
      } else {
        return '';
      }
    } else if (this.router.url === '/dispatch-request/dispatch-request-listview-info') {
      if (rowData.dispatchStatusId === 510) {
        return 'bg-request-new';
      } else if (rowData.dispatchStatusId === 520) {
        return 'bg-request-dispatch-assigned';
      } else if (rowData.dispatchStatusId === 530) {
        return 'bg-request-accepted';
      } else if (rowData.dispatchStatusId === 560) {
        return 'bg-request-enroute';
      } else if (rowData.dispatchStatusId === 570) {
        return 'bg-request-cancelled';
      } else if (rowData.dispatchStatusId === 580) {
        return 'bg-request-onscene';
      } else if (rowData.dispatchStatusId === 590) {
        return 'bg-request-completed';
      } else if (rowData.dispatchStatusId === 600) {
        return 'bg-request-dispatch-completed';
      } else {
        return '';
      }
    } else if (this.router.url === '/followup') {
      const CurrentDate = moment().tz(rowData.timezone).valueOf();
      if (CurrentDate > rowData.followUpDateTime && rowData.followUpStatus.innerText === 'Open') {
        return 'bg-red';
      } else {
        return '';
      }

    } else {
      return '';
    }
  }

  getUrlExport(url) {
    const that = this;
    let params = 'searchText=' + this.search;
    params += '&sortColumn=';
    params += this.sortF;
    params += '&sortType=';
    params += this.sortO;
    let buildUrl = url;
    if (url.lastIndexOf('?') < 0) {
      buildUrl += '?';
    } else {
      buildUrl += '&';
    }
    if (this.url.lastIndexOf('?') < 0) {
      this.gridUrlParams = '';
    } else {
      this.gridUrlParams = this.url.substring(this.url.lastIndexOf('?'));
      this.gridUrlParams = this.gridUrlParams.replace('?', '&');
    }
    buildUrl += params;
    return buildUrl;
  }

  onPaste(event: ClipboardEvent) {
    if (!this.isPasteEnabled) {
      event.preventDefault();
    }

  }
}
