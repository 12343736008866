import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class MiTranslateLoaderService implements TranslateLoader {

  getTranslation(lang: string): Observable<any> {
    return of({
      vehicle_type_slatus_change: 'Are you sure you want to {{statusLable}} this Vehicle Type?',
      label_id: 'ID',
      label_id_mrn: 'ID/MRN',
      Username: 'Email',
      Password: 'Password',
      exceed_max_length: 'Max length is exceed',
      password_too_short: 'Password is too short',
      comp_name: 'Company Name',
      label_sign_in: 'Sign In',
      label_log_in: 'Login',
      msg_logout: 'Are you sure that you want to perform this action?',
      lbl_confirm_idle_session: `Session has been idle over its time limit,
      It will be disconnected in 2 minutes. Click on "Yes" to continue session.`,
      label_sign_in_welcome_text: 'Welcome to the transport evolution',
      label_nemt_pulse: 'NEMT PULSE',
      label_sign_in_description: 'Powerful dispatching, scheduling and appointment management tool for medical transportation',
      label_read_more: 'Read More',
      about_comp: 'About Company',
      label_company_website: 'Company Website',
      label_company_location: 'Company Location',
      label_personal_information: 'Personal Information',
      address: 'Address',
      address_col: 'Address',
      label_house_address: 'House Address',
      member_address: 'Patient Address',
      rating_col: 'Rating',
      noOfBookings: 'No. of Bookings',
      address_line1: 'Address Line 1',
      address_line2: 'Address Line 2',
      web: 'Website',
      phone: 'Phone Number',
      label_phone: 'Phone',
      label_phone_number: 'Phone Number',
      label_primary_phone: 'Primary Phone',
      label_secondary_phone: 'Secondary Phone',
      label_emailid: 'Email Id',
      label_mrn: 'MRN',
      label_address_line_one: 'Address Line 1',
      label_primary_mobile_no: 'Primary Phone Number',
      label_secondary_mobile_no: 'Secondary Phone Number',
      fax: 'Fax',
      invalid: 'Invalid amount',
      label_amount: 'Amount',
      label_pay: 'Pay',
      invalid_hours: 'Invalid hours',
      invalid_package: 'Invalid route name',
      package: 'Route name required',
      hours: 'Hours required',
      cancellation: 'Cancellation fee required',
      add_hours: 'Additional hours required',
      addKm: 'Additional KM. required',
      base_fare: 'Base fare required',
      driver_percentage_req: 'Provider percentage is required',
      amount_req: 'Amount is required',
      distance_amount: 'Invalid distance amount',
      distance_invalid: 'Invalid distance',
      category: 'Category type is required',
      service: 'Service type is required',
      distance: 'Distance required',
      distance_fare: 'Distance fare required',
      wait_fare: 'Wait time fare required',
      ride_fare: 'Ride time fare required',
      hours_time: 'Hours',
      mins: 'Minutes',
      secs: 'Seconds',
      route_fare: 'Fare required',
      route_radius: 'Radius required',
      route_radius_src: 'Radius required',
      route_dest: 'Destination required',
      pickup_time_validation: 'Please select valid time',
      add_destination_validation: 'Please add Destination',
      please_add_value_to_settings: 'Please add time, 0 is not allowed',
      atleast_one_destination: 'Atleast have one Destination',
      pickup_location_validation_msg: 'Please select proper pickup location',
      pickup_location: 'Pickup location required',
      car_type_req: 'Car type Required',
      hotel_req: 'Hotel Required',
      quotation_amount_req: 'Quotation amount required',
      route_pattern: 'Use numbers only',
      amount_pattern: 'Use numbers with a decimal only',
      amount_pattern_without_zero: 'Use numbers without decimal',
      route_length_src: 'Range 0.1 to 1 KM',
      route_pattern_src: 'Use numbers only',
      invalid_number: 'Invalid Number',
      label_fullname: 'Full Name',
      f_name: 'First Name',
      m_name: 'Middle Name',
      l_name: 'Last Name',
      label_first_name: 'First Name *',
      label_last_name: 'Last Name *',
      label_phone_num: 'Phone Number *',
      label_npi: 'NPI Number',
      email: 'Email ID',
      label_email: 'Email',
      label_provider_name: 'Provider Name',
      label_enter_email_address: 'Enter email address',
      label_select_organization: 'Select organization',
      label_email_id: 'Email',
      label_verify_email: 'Verify Email',
      label_email_imp: 'Email *',
      document_type: 'Document type is required',
      docRegNo: 'Registration No is required',
      label_create_dept: 'Create Department',
      label_edit_dept: 'Edit Department',
      regId: 'Document ID',
      min_length: 'Minimum 3 characters required',
      min_length_1: 'Minimum 1 characters required',
      min_length_2: 'Minimum 2 characters required',
      max_length: 'Exceeded character limit',
      issue_date: 'Date of issue required',
      expiry_date: 'Date of expiry required',
      end_date_required: 'Date of end required',
      start_date: 'Start date is required',
      end_date: 'End date is required',
      document: 'Document required',
      id_num: 'ID Num is required',
      driver_name: 'Provider name is required',
      name_pattern: 'Invalid name',
      vehicle_pattern: 'Invalid vehicle name',
      gps_id_pattern: 'Invalid gps id',
      provider_name_pattern: 'Invalid Provider Name',
      team_pattern: 'Invalid team name',
      pattern_alphabets: 'Use only alphabets',
      phone_invalid: 'Enter a valid phone number',
      mobile_invalid: 'Enter a valid mobile number',
      number_pattern: 'Use numbers only',
      fax_pattern: 'Invalid fax number',
      email_required: 'Email is required',
      err_invalid_emailId: 'Please enter valid email address',
      lebel_date_birth: 'Date of Birth',
      dob_required: 'Date of birth is required',
      lebel_gender: 'Gender',
      gender_required: 'Gender is required',
      lebel_sex: 'Gender',
      err_sex_required: 'Gender is required',
      provider_type_required: 'Provider type is required',
      assign_vehicle_required: 'Assign Vehicle is required ',
      assign_team_members_required: 'Assign Team Members required',
      vehicle_type_required: 'Vehicle Type is required',
      login_type_required: 'Login Type is required',
      user_role_required: 'User Role is required',
      service_region_required: 'Service Region is required',
      service_region_location: 'Service location is required',
      dispatch_type_required: 'Dispatch Type is required',
      default_timezone_required: 'Default timezone  is required',
      health_plan_required: 'Health plan is required',
      primary_market_required: 'Primary market  is required',
      associated_markets_required: 'Associated market  is required',
      specialization_required: 'Specialization is required',
      qualification_required: 'Qualification is required',
      description_required: 'Description is required',
      email_pattern: 'Email ID is invalid',
      address_req: 'Address is required',
      special_notes_req: 'Special notes is required',
      country_req: 'Country is required',
      state_req: 'State is required',
      company_name: 'Company name is required',
      city_req: 'City is required',
      city_pattern: 'Invalid city',
      approval_options: 'Select approval option',
      label_zipcode: 'Zipcode',
      zipcode_req: 'Zip code is required',
      zipcode_invalid: 'Invalid zip code',
      account_number_invalid: 'Invalid account number',
      banck_code_invalid: 'Invalid account number',
      transmission_type: 'Transmission type required',
      invalid_staff_transport_name: 'Invalid name',
      contact_person_name: 'Contact person name is required',
      label_contact_number: 'Contact Number',
      label_mobile_number: 'Mobile Number',
      label_contact: 'Contact',
      contact_number_require: 'Phone number is required',
      contact_number_req: 'Mobile Number is required',
      comp_name_required: 'Company name required',
      about_comp_required: 'About company required',
      website_required: 'Website required',
      phone_required: 'Phone number required',
      fax_required: 'Fax number required',
      invalid_site: 'Invalid URL',
      service_type: 'Service Type',
      label_service_area: 'Service Area',
      category_t: 'Vehicle Type',
      vehicle_no: 'Vehicle No.',
      vehicle_details: 'Vehicle Make And Model',
      vehicle_not_assigned: 'No vehicle assigned',
      vehicle_details_req: 'Required vehicle make and model',
      vehicle_make: 'Vehicle Make',
      model_yr: 'Model year',
      capacity: 'Capacity',
      lebel_id: 'ID',
      dev_id: 'Device ID',
      condition: 'Vehicle Condition',
      reg_no: 'Registration No.',
      date_issue: 'Date Of Issue',
      date_expiry: 'Date of Expiry',
      label_expired: 'Expired',
      label_empty_message: 'No data found',
      updated: 'Updated On',
      question_req: 'Question required',
      answer_req: 'Answer required',
      hotel_name: 'Hotel name required',
      dispatcher_req: 'Dispatcher required',
      corporate_name: 'Please enter corporate name',
      corporate_code: 'Please enter corporate code',
      name_invalid: 'Please enter valid name',
      corporate_contact_person_name: 'Please enter corporate person name',
      corporateCareExecutive: 'Please select a care executive',
      car_type: 'Car type required',
      drop_location: 'Drop location required',
      booking_date: 'Pickup date required',
      booking_date_and_time_req: 'Pickup date & time is required',
      return_booking_date_and_time_req: 'Return Pickup date & time is required',
      appointment_date_and_time_req: 'Appointment date & time is required',
      passenger_name: 'Passenger name required',
      Package_req: 'Package required',
      cmm_req: 'Car make & model required',
      service_type_req: 'Service Type required',
      note_req: 'Charge code required',
      charge_req: 'Note required',
      deptAdmin_req: 'Assign Department Admin required',
      name_req: 'Name required',
      empId_req: 'Employee id required',
      empId_pattern: 'Invalid Employee id',
      department_req: 'Department required',
      message_passenger_not_assigned: 'Passenger data is not found',
      message_driver_not_assigned: 'Provider not assigned',
      label_date_time: 'Date & time',
      label_date_time_list: 'Date & time',
      label_name: 'Name',
      label_patient_name: 'Patient Name',
      label_topup: 'Top Up',
      label_successful_trip: 'Successful Appointments',
      label_distance_travelled: 'Distance Travelled (Km)',
      label_total_trip_amount: 'Total Appointment Amount',
      label_credit: 'Credit',
      label_duty_time: 'Duty Time',
      label_bank_name: 'Bank Name',
      label_bank_name_req: 'Bank name is required',
      label_: '',
      label_average_rating: 'Average Rating',
      label_driver_details_header: 'Provider Details',
      label_passenger_details_header: 'Passenger Details',
      label_status: 'Status',
      label_account_status: 'Account Status',
      label_account_number: 'Account Number',
      label_account_number_req: 'Account number is required',
      label_confirm_account_number: 'Confirm account number',
      label_confirm_account_number_req: 'Confirm account number is required',
      label_bank_code_header: 'Bank Code',
      label_bank_code: 'Bank Code (Routing Number/IFSC)',
      label_bank_code_req: 'Bank code (Routing Number/IFSC) is required',
      label_account_type: 'Account Type',
      label_account_type_req: 'Account type is required',
      label_rental_docs_uploaded: 'Rental Documents Uploaded',
      label_hotel_booking: 'Hotel Bookings',
      label_room_num: 'Room No.',
      label_quotation_amount_accepted: 'Accepted Quotation Amount',
      lable_room_no_req: 'Please Enter Room No.',
      label_booking: 'Bookings',
      label_tripID: 'Appointment ID',
      label_balance: 'Balance',
      label_trip_id: 'Appointment ID:',
      label_trip_id_text: 'Appointment ID',
      label_vehicle_id: 'Vehicle ID',
      label_driver_id: 'Provider ID',
      label_member_id: 'Patient ID',
      label_mem_id: 'Member ID',
      label_provider_id: 'Provider ID',
      label_service_id: 'Service Type:',
      label_add_amount: 'Add Top Up Amount',
      label_pickup: 'Pickup',
      label_pickup_header: 'Pick Up',
      label_dropup_header: 'Drop Up',
      label_hotel_succuss: 'Hotel added successfully',
      label_pickup_date_time: 'PickUp Date & Time',
      label_return_pickup_date_time: 'Return PickUp Date & Time',
      label_appointment_date_time: 'Appointment Date & Time',
      label_trip_date: 'Pickup Date and Time',
      label_pickup_latLong: 'Pickup Latitude-Longitude',
      label_appointment_date: 'Appointment Date',
      label_appointment_time: 'Appointment Time',
      label_trip_date_time_elapsed: 'Pickup Date and Time is elapsed',
      label_From_date_greater: 'To Date should be greater then From Date',
      label_return_trip_date_time_elapsed: 'Return pickup date & time should be greater than pickup date & time',
      label_return_trip_date_time_elapsed_with_appointment:
        'Return pickup date & time should be greater than pickup date & time and appointment date & time',
      label_appointment_date_time_elapsed: 'Appointment date & time should be greater than pickup date & time',
      label_return_trip_date_time_retype: 'Patient has other appointment at same pickup date and time so cannot create the appointment',
      label_pickup_date_time_without_col: 'PickUp Date & Time',
      label_no_driver: 'No provider is available',
      label_filter: 'FILTER',
      label_drop_date_time_without_col: 'Drop Date & Time',
      label_vehicle_type: 'Vehicle Type',
      label_vehicle_type_name: 'Vehicle Name',
      label_enter_vehicle_name: 'Enter Vehicle Name',
      label_vehicle_type_header: 'Vehicle Type',
      label_trip_type: 'Appointment Type',
      label_trip_booked: 'Appointment Booked by',
      label_doc_expiry_header: 'Docs Expiring in',
      label_vehicle_icon_header: 'Vehicle Icon',
      label_vehicle_type_text: 'Vehicle type',
      label_select_language: 'Select Language',
      noOfPackages: 'No. of Packages',
      label_add_vehicle_type: 'Add Vehicle Type',
      label_edit_vehicle_type: 'Edit Vehicle Type',
      label_change_vehicle_name: 'Change Vehicle Name',
      label_no_of_passenger: `No. of Passenger's `,
      label_view_note_header: 'View Note',
      label_rejected: 'You have rejected',
      label_package: 'Package',
      label_get_estimation: 'Get Estimation',
      label_one_way_estimation: 'Estimation for one way appointment',
      label_km: 'Km',
      label_hr: 'Hr',
      label_mins: 'Mins',
      label_previous_quotation: 'Previous Quotations',
      label_service_type_header: 'Service Type',
      label_quoation_amount: 'Quotation amount',
      label_quoation_note: 'Note',
      label_qoutation_reason_for_cancel: 'Reason',
      invalid_amount: 'Invalid amount',
      invalid_user_creditionals: 'Invalid UserName or Password',
      forgot_password: 'Forgot Password?',
      select_area: 'Select Area',
      label_car_type: 'Car Type',
      label_car_make_model: 'Car make & model',
      label_pickup_location: 'Pickup Location',
      label_drop_location: 'Drop Location',
      label_current_location: 'Current Location',
      label_location: 'Location Name',
      label_location_header: 'Location',
      lbl_location_last_updated: 'Location Last Updated At',
      label_RECUR_AFTER_EVERY: 'RECUR AFTER EVERY',
      label_recurrence_type: 'Please Select  Recurrence Type',
      label_search_by_provider: 'Search By Provider ID Or Name',
      label_search_pickup: 'Search Pickup Location',
      label_search_drop: 'Search Drop Location',
      label_search_team: 'Search by Team Name',
      label_search_user: 'Search by User',
      label_search_team_member: 'Search by team name/member',
      label_no_team_members: 'No team members available',
      label_search_web_portal_member: 'Search by member',
      label_week: 'WEEK',
      lebel_npi: 'NPI',
      label_last_sync: 'Last Synced',
      room_num_max_length: 'Max. 9 character allowed',
      label_member_search: 'Search Patient',
      label_driver_search: 'Search Provider',
      label_passenger_name: 'Passenger Name',
      label_member_name: 'Patient Name',
      label_driver_mobile_no: 'Provider Mobile No.',
      label_mobile: 'Mobile Number',
      label_alternate_number: 'Alternate Number',
      label_assign_driver: 'Assign Provider',
      label_reassign_driver: 'Reassign Provider',
      label_assign_drivers: 'Assign Providers',
      label_note: 'Note',
      label_packages: 'Packages',
      label_CMM: 'Make',
      label_assign_team_members: 'Assign Team Members',
      label_assign_teams: 'Assign Teams',
      label_assign_vehicle: 'Assign Vehicle',
      label_No_Passenger: 'No Passenger',
      label_from_date: 'From Date',
      label_pickup_from_date: 'Pickup From Date',
      label_from: 'From',
      label_to: 'To',
      label_occurrence: 'Occurrence',
      label_start_date: 'Start Date',
      label_passenger_registered_with: 'Passenger Registered with',
      label_to_date: 'To Date',
      label_pickup_to_date: 'Pickup To Date',
      label_end_date: 'End Date',
      label_start_date_registration: 'Start Date (Registration)',
      label_role: 'Role',
      label_service_region: 'Service Region',
      label_service_locations: 'Service Locations',
      label_service_locations_req: 'Service Location is required',
      label_login_type: 'Login Type',
      label_usre_role: 'User Role',
      label_invalid_role: 'Invalid role, contact to server team',
      label_ID_Number: 'ID No.',
      label_address_line_1: 'Address line 1',
      label_address_line_2: 'Address line 2',
      label_address_type: 'Address Type',
      label_country: 'Country',
      label_state: 'State',
      label_city: 'City',
      label_county: 'County',
      label_approval_by_admin: 'Approval by Admin',
      label_zip_code: 'Postal Code',
      zip_code: 'Zip Code',
      label_look_up_client_ehr: 'Look-up Client in EHR',
      label_look_up_client_dms: 'Look-up Client in DMS',
      label_create_new_contact: 'Create New Contact Form',
      label_PO_box: 'PO box',
      label_assign_role: 'Assign Role',
      label_No: 'No.',
      label_read: 'READ',
      label_select: 'Select',
      label_select_hotel: 'Select Hotel',
      label_driver_name: 'Provider Name',
      label_driver_email: 'Provider Email',
      label_driver_phone_num: 'Provider Phone No.',
      label_driver: 'Provider',
      label_shift_end_on: 'Shift Ends On',
      label_delete_shift_schedule: 'Delete Shift Schedule',
      label_member: 'Patient',
      label_new_member: 'Add New Patient',
      label_booking_type: 'Booking Type',
      label_ride_schedule_at: 'Ride Schedule At',
      label_ride_now: 'Ride Now',
      label_ride_later: 'Ride Later',
      label_registration_state: 'Registration State/Province',
      label_registration_state_req: 'Registration State/Province is Required',
      label_booking_service: 'Booking Service',
      label_booking_services: 'Booking Services',
      label_payment_mode: 'Payment Mode',
      label_invoice_date: 'Invoice Date',
      label_invoice_amount: 'Invoice Amount',
      label_refund_date: 'Refund Date',
      label_refund_amount: 'Refund Amount',
      err_refund_exceeds_value: 'Refund value exceeds total amount',
      label_passenger_type: 'Passenger Type',
      label_completed_bookings: 'Completed Bookings',
      label_cancelled_bookings: 'Cancelled Bookings',
      label_active_bookings: 'Active Bookings',
      label_total_amount: 'Total Amount',
      label_payment_received: 'Payment Received',
      label_payment_due: 'Payment Due',
      label_booking_code: 'Booking Code',
      label_booking_count: 'Booking Counts',
      label_waiting_time: 'Waiting Time',
      label_km_in: 'KMs In',
      label_km_covered: ' KM Covered',
      label_km_out: 'KMs Out',
      label_total_vehicles: 'Total Vehicles',
      label_taxi_drivers: 'Taxi Providers',
      label_rental_car_drivers: 'Rental Car Providers',
      label_hotel_dispatchers: 'Hotel Dispatchers',
      label_corporate_dispatchers: 'Corporate Dispatchers',
      label_no_of_bookings: 'No. of Bookings',
      label_month: 'Month',
      label_year: 'Year',
      label_transmission_type: 'Transmission Type',
      label_ative_package_info: 'Active Package Information',
      label_expires_today: 'Expires Today',
      label_already_expired: 'Already Expired',
      label_package_not_started: 'Package is not started',
      label_registration_num: 'Registration No.',
      label_date_issue: 'Date of Issue',
      label_date_expiry: ' Date of Expiry',
      label_date_end: ' Date of End',
      label_documents: 'Documents',
      label_document: 'Document',
      label_document_type: 'Document Type',
      label_category: 'Category',
      label_VM: 'Vehicle Make',
      label_vehicle_name: 'Vehicle Name',
      label_team_name: 'Team Name',
      label_shift_start_date: 'Shift Start Date',
      label_shift_start_date_title: 'SHIFT START DATE',
      label_VM_name: 'Model Name',
      label_vehicle_no: 'Vehicle No.',
      label_vehicle_reg_no: 'Vehicle Registration Number',
      label_reg_no: 'Registration Number',
      label_reg_no_required: 'Registration number is required',
      label_vehicle_number: 'License Plate Number',
      label_vehicle_number_with_colon: 'License Plate Number :-',
      label_model: 'Model',
      label_model_year: 'Model Year',
      label_model_color: 'Model Color',
      label_residence: 'Residence',
      label_providers: 'Providers',
      label_groups_details: 'Groups Details',
      label_manage_groups: 'Manage Groups',
      label_assigned_teams: 'Assigned Teams',
      label_providers_name: 'Providers Name',
      label_insurance_details: 'Insurance Details',
      label_group_number: 'Group number',
      label_group_name: 'Group Name',
      label_languages_known: 'Languages Known',
      label_languages_preferred: 'Languages Preferred',
      label_race: 'Race',
      label_ethnicity: 'Ethnicity',
      label_primary_chronic_condition: 'Primary chronic condition',
      label_program_enrolled: 'Program Enrolled',


      label_capacity: 'Capacity',
      label_ETA: 'ETA',
      label_car_condition: 'Car Condition',
      label_device_id: 'Device ID',
      category_req: ' Category Required',
      vehicle_number_req: ' License Plate Number Required',
      vehicle_number_pattern: 'Invalid license plate number',
      vehicle_model_year_req: 'Model year Required',
      vehicle_model_year_pattern: 'Invalid Model year',
      capacity_req: 'Capacity is Required',
      two_digit_max: 'Only 2 digits allowed',
      car_condition_req: 'Car Condition Required',
      device_id_req: 'Device ID Required',
      aplha_numeric_pattern: 'Use only alpha-numeric characters',
      label_dashboard: 'Dashboard',
      label_sales_quotations: 'Sales Quotations',
      label_Passenger_type: 'Type of Passengers',
      label_Passengers: 'Passengers',
      label_Dispatchers: 'Dispatchers',
      label_Vehicles: 'Vehicles',
      label_Vehicle: 'Vehicle',
      label_Drivers: 'Providers',
      label_provider: 'Provider',
      label_Staff_Transport: 'Staff Transport',
      label_hotel: 'Hotel',
      label_Hotels: 'Hotels',
      label_corporate: 'Corporate',
      label_Corporates: 'Corporates',
      label_private: 'Private',
      label_App_Settings: 'App Settings',
      label_Settings: 'Settings',
      label_Reports: 'Reports',
      label_region: 'Region',
      label_reference_setting: 'Reference Setting',
      label_regions: 'Regions',
      label_taxes: 'Taxes',
      admin_settings: 'Admin Settings',
      label_add_region: 'Add Region',
      label_edit_region: 'Edit Region',
      label_promo_title: 'Promo title',
      lable_promo_title_require: 'Title is required',
      lable_promo_code_require: 'Code is required',
      lable_promo_date_range_require: 'Date range is required',
      lable_usage_require: 'Limit is required',
      lable_usage_pattern: 'invalid limit',
      lable_discount_require: 'Discount is required',
      lable_max_discount_require: 'Max discount is required',
      lable_discount_pattern: 'Invalid Discount',
      lable_max_discount_pattern: 'Invalid max discount pattern',
      lable_already_uploadd_document: 'Document already uploaded for this type.',
      lable_close_package_permision: 'Are you sure do you really want to close this Package?',
      lable_no_vehicle_type_found: 'No Vehicle Type found',
      lable_no_vehicle_found: 'No Vehicle found',
      lable_updated_successfully: 'Updated successfully',
      lable_added_succesfully: 'Added succesfully',
      label_promo_code: 'Promo Code',
      label_no_of_uses: 'No. of uses',
      label_usage_limit: 'Usage Limit',
      label_per_user_usage: 'Usage Limit per User',
      label_add_promo_code: 'Add Promo Code',
      label_update_promo_code: 'Edit Promo Code',
      label_date_range: 'Date Range',
      label_usage: 'Usage',
      label_usage_count: 'Usage Count',
      label_limit_per_user: 'Limit per user',
      label_mode: 'Mode',
      label_discount: 'Discount',
      label_max_discount: 'Max Discount',
      label_time_settings: 'Time Settings',
      label_Pricing: 'Pricing',
      label_Routes: 'Routes',
      label_edit: 'Edit',
      label_update: 'Update',
      label_delete: 'Delete',
      label_add: 'Add',
      label_package_name: 'Package Name',
      label_base_fare: 'Base Fare',
      label_base_fare_placeholder: 'Base Fare *',
      label_booking_fee: 'Booking Fee',
      label_distance: 'Distance',
      label_distance_fare: 'Additional Distance Fare Per KM',
      label_wait_time_fare: 'Wait Time Fare',
      label_wait_time_fare_placeholder: 'Wait Time Fare (Minutes) *',
      label_wait_time_fare_mins: 'Wait Time Fare (Minutes)',
      label_ride_time_fare: 'Ride Time Fare',
      label_cancellation_fee: 'Cancellation Charges',
      label_cancellation_fee_placeholder: 'Cancellation Charges *',
      label_actions: 'Actions',
      label_action: 'Action',
      label_details: 'Details',
      label_create_trip: 'Create Appointment',
      label_trip_created: 'Appointment Created at',
      label_trip_created_by: 'Appointment Created by',
      label_additional_hours: 'Additional Rate Per Hour',
      label_hours: 'Hours',
      label_additional_KM: 'Additional Rate Per KM',
      label_category_type: 'Vehicle type',
      label_distance_unit: 'Distance (KM)',
      label_distance_for_base_fare: 'Distance (KMs) For Base Fare',
      label_send: 'Send',
      label_save: 'Save',
      label_book: 'Book',
      label_basic_details: 'Basic Details',
      label_cancel: 'Cancel',
      please_select: 'Please Select',
      label_all_cities: 'All Cities',
      label_route_name: 'Route Name',
      label_radius: 'Radius',
      label_radius_m: 'Radius(meters)',
      label_radius_unit: 'Radius(KM)',
      label_no_routes: 'No. of Routes',
      label_updated_on: 'Updated On',
      label_Radius_Meters: 'Radius (Meters)',
      label_source: 'Source',
      label_destination: 'Destination',
      label_add_destination: 'Add Destination',
      label_fare: 'Fare',
      label_fares: 'Fares',
      label_add_route: 'Add Route',
      label_update_route: 'Edit Route',
      label_add_packages: 'Add Packages',
      label_update_packages: 'Update Packages',
      label_FAQ: 'FAQ',
      label_description: 'Description',
      label_qualification: 'Qualification',
      label_description_placeholder: 'Description *',
      label_terms_conditions: 'Terms & Conditions',
      label_privacy_policy: 'Privacy Policy',
      label_rental_privacy_policy: 'Rental Policy',
      label_chauffeur_privacy_policy: 'Chauffeur Policy',
      label_password_match: 'password does not match',
      label_docs_validation: 'Add atleast one document',
      label_otp: 'Please enter OTP',
      label_otp_header: 'OTP',
      label_otp_msg: 'We will send One Time Password (OTP) on your registered email to reset password',
      label_current_km: 'Please enter Current KM',
      label_enter: 'Please enter',
      invalid_otp: 'Invalid OTP',
      verified_otp: 'OTP Verified',
      matched_otp: 'OTP matched',
      label_our_partners: 'Our Partners',
      label_choose: 'Choose',
      label_upload: 'Upload',
      label_upload_trip_data: 'Upload Appointment Data',
      label_upload_document: 'Upload Document',
      label_choose_file: 'Choose File',
      label_qusetion: 'Question',
      label_answer: 'Answer',
      label_clear: 'Clear',
      label_edit_term_Condition: 'Edit Terms & Conditions',
      label_edit_privacy_policy: 'Edit Privacy Policy',
      label_edit_rental_policy: 'Edit Rental Policy',
      label_edit_chauffeur_policy: 'Edit Chauffeur Policy',
      label_add_partner: 'Add Partner',
      label_hash: '#',
      label_corporate_info: 'Corporate Info',
      label_contact_person: 'Contact Person',
      label_department_count: 'Total Departments',
      label_registration_Date: 'Registration Date',
      label_total_bookings: 'Total Bookings',
      label_deactivate: 'Deactivate',
      label_activate_edit: 'Active & Edit',
      label_activate: 'Activate',
      label_start_call: 'Start Session',
      label_end_call: 'End Session',
      label_start_zoom_call: 'Re-join',

      label_Total: 'Total',
      label_active: 'Active',
      label_deactive: 'Deactive',
      label_Active: 'active',
      label_Deactive: 'deactive',
      label_cancelled: 'Cancelled',
      label_completed: 'Completed',
      label_new: 'New',
      label_regret: 'Regret',
      label_corporate_information: 'Corporate Information',
      label_package_pricing: 'Package Pricing',
      lable_route_pricing: 'Route Pricing',
      label_corporate_name: 'Corporate Name',
      label_corporate_code: 'Corporate Code',
      label_contact_person_name: 'Contact Person Name',
      label_assign_corporate_executive: 'Assign Corporate Care Executive',
      label_save_next: 'Save & Next',
      label_save_quit: 'Save & Quit',
      label_add_princing: 'Add Pricing',
      label_back: 'Back',
      label_finish: 'Finish',
      label_next: 'Next',
      label_quit: 'Quit',
      label_additional_hours_fee: 'Additional Hours Rate',
      label_hotel_info: 'Hotel Info',
      label_dispatcher_name: 'Dispatcher Name',
      label_dispatcher_no: 'Dispatcher No.',
      label_hotel_name: 'Hotel Name',
      label_dispatcher_details: 'Dispatcher Details',
      label_hotel_information: 'Hotel Information',
      labe_notifications_to: 'to',
      label_notifications: 'Notifications',
      label_no_notification_found: 'No more notification found',
      label_no_driver_found: 'No provider found',
      label_date_and_time: 'Date & time',
      label_resend: 'Resend',
      label_vehicle_info: 'Vehicle info',
      label_vehicle_count: 'Vehicle Count',
      label_assign: 'Assign',
      label_add_documents: 'Add Documents',
      label_add_documents_details: 'Attached Documents Details',
      label_rating: 'Ratings',
      label_avg_rating: 'Avg. Ratings',
      label_yes: 'Yes',
      label_no: 'No.',
      label_no_without_dot: 'No',
      label_add_new_follow_up: 'Add New Follow Up',
      label_save_as_draft: 'Save as Draft',
      label_submit: 'Submit',
      label_all: 'All',
      label_busy: 'Busy',
      label_available: 'Available',
      label_not_working: 'Not Working',
      label_no_records_available: 'No records available.',
      label_idle: 'Idle',
      label_mailing_address: 'Mailing Address',
      label_off_duty: 'Off Duty',
      label_S_NO: 'S.No.',
      label_vehicle: 'Vehicle',
      label_vehicle_code: 'Vehicle Code',
      label_accesse_name: 'Accesses Name',
      label_card_details: 'Card Details',
      label_dispatcher: 'dispatcher',
      label_trip_completed: 'Appointments Completed',
      label_user_type: 'User Type',
      label_shift_name: 'Shift Name',
      label_shift_name_title: 'SHIFT NAME',
      label_shift_end_date: 'Shift End Date',
      label_vehicleNumber: 'Vehicle Number',
      label_active_vehicle: 'Active Vehicles',
      label_inactive_vehicle: 'Inactive Vehicles',
      label_idle_vehicle: 'Idle Vehicles',
      label_driverID: 'Provider ID',
      label_user_details: 'User Details',
      label_team_member_details: 'Team Member Details',
      label_manage_team_members: 'Manage Team Members',
      label_trip_amount: 'Appointment Amount',
      label_bank_details: 'Bank Details',
      label_trip_date_time: 'Appointment Date & Time',
      label_BookingId: 'Booking ID',
      label_trip_status: 'Appointment Status',
      label_source_address: 'Source Address',
      label_destination_address: 'Destination Address',
      label_reset: 'Reset',
      label_apply: 'Apply',
      label_reset_filter: 'Reset Filter',
      label_passenger: 'Passenger',
      label_pickup_drop_location: 'Pickup & Drop location',
      lable_pickup_date: 'Pickup Date',
      lable__date_time: 'Date and Time',
      lable_pickup_date_time: 'Pickup Date and Time',
      lable_select_date_time: 'Select Date & Time',
      lable_select_date: 'Select Date',
      label_booking_ID: 'Booking ID',
      lable_upload_quote_pdf: 'Upload Quote (PDF)',
      label_booking_Date: 'Booking Date',
      label_upload_quote: 'Upload Quote',
      label_start_trip: 'Start Appointment',
      label_end_trip_message: 'Appointment is ended successfully',
      label_extend_trip: 'Extend Appointment',
      label_end_trip: 'End Appointment',
      label_cancel_trip: 'Cancel Appointment',
      label_security_check: 'Security Check',
      label_delivery_check: 'Delivery Check',
      label_car_return_check: 'Car Return Check',
      label_return_security_check: 'Return Security Check',
      label_resend_quote: 'Resend Quote',
      label_taxi: 'Taxi',
      label_rental_car: 'Rental Car',
      label_chauffeur: 'Chauffeur',
      label_booking_status: 'Booking Status',
      label_payment_status: 'Payment Status',
      label_search: 'Enter text to search.',
      label_search_header: 'Search',
      label_charge_code: 'Charge Code',
      label_department: 'Department',
      label_department_admin: 'Department Admin',
      lable_employeeID: 'Employee ID',
      label_active_trips: 'Active Appointments',
      label_completed_trips: 'Completed Appointments',
      label_upcoming_trips: 'Upcoming Appointments',
      label_critical_trips: 'Critical Appointments',
      label_cancelled_trips: 'Cancelled Appointments',
      label_driver_availability: 'Provider Availability',
      label_on_trips: 'On Appointment',
      label_on: 'ON',
      label_off: 'OFF',
      label_assign_department: 'Assign Department Admin',
      lable_department_name: 'Department Name',
      lable_employee_name: 'Employee Name',
      label_no_trips: 'No. of Appointments',
      label_view_details: 'View Details',
      label_mark_vip: 'Mark As VIP',
      label_approve: 'Approve',
      label_reject: 'Reject',
      label_pending_approval: 'Pending for Approval',
      label_manage_employee: 'Manage Employees',
      label_manage_departments: 'Manage Departments',
      label_pricing_details: 'Pricing Details',
      label_taxi_service: 'Taxi Service',
      label_rental_car_service: 'Rental Car Service',
      label_chauffeur_service: 'Chauffeur Service',
      label_start_end_date: 'Start-End Date',
      labee_pickup_time: 'Pickup Time',
      label_staff_transport: 'staff-transport',
      label_day: 'Days',
      label_Assign_vehicle: 'Assign Vehicle',
      label_View_Assign_vehicle: 'View Assign Vehicle',
      label_total_fare: 'Total Fare (TNZ)',
      label_holiday: 'Holidays',
      label_another_drop_location: 'Another Drop Location',
      label_type: 'Type',
      label_view_route: 'View Route info',
      label_route_information: 'Route Information',
      label_renew_package: 'Renew Package',
      label_track_driver: 'Track Provider',
      label_car_no: 'Car No.',
      charge_code_maxlength: 'Allowed maximum 4 character',
      label_Cancelation: 'Cancellation Reasons',
      label_cancellation_reason: 'Cancellation Reason',
      label_invalid_cancellation_reason: 'Invalid cancellation Reason',
      label_booking_cancel: 'Cancel Appointment',
      label_rating_comments: 'Rating & Comments',
      label_reply: 'Reply',
      label_cancel_reason: 'Cancel Reason',
      label_reason: 'Reason',
      label_trip_history: 'Appointment History',
      label_forgot_password: 'Forgot Password',
      label_forgot: 'User Name/ Email /Phone No.',
      label_validate_otp: 'Validate OTP',
      cancel_reason_req: 'Cancel Reason Required',
      label_cancel_booking: 'Cancel Appointment',
      label_country_code: 'Country Code',
      country_code_required: 'Please select country code',
      country_code_invalid: 'Country code is invalid',
      booking_details_result: 'No booking record found',
      booking_comment_details: 'No comment',
      label_comment: 'Comment',
      comment_heading: 'Rating & Comments',
      view_all: 'View All',
      label_driver_type: 'Provider Type',
      label_confirmation: 'Confirmation',
      label_dispatch_confirmation: 'Dispatch Confirmation',
      label_select_access: 'Select Access',
      label_confirm_extend_package: 'Do you really want to Extend Package?',
      label_extend_package_confirmation_header: 'Extend Package Confirmation',
      label_confirm_emp_confirm_booking_p1: 'Are you sure that you want to',
      lable_confirm_emp_confirm_booking_p2: ' this booking',
      label_select_service_type: 'Select service type',
      label_invalid_user: 'Invalid User Name/ Email /Phone No.',
      label_driver_location: 'Provider Location',
      label_report_driver_login_logout: 'Provider Login-Logout',
      label_report_driver_on_duty: 'Provider On Duty',
      label_report_driver_income: 'Provider Income',
      label_report_driver_mileage: ' Provider Mileage',
      label_report_driver_performance: 'Provider Performance',
      label_report_car_mileage: 'Car Mileage',
      label_report_transction: 'Transaction',
      label_report_refund: 'Refund',
      label_report_promo_code: 'Promo Code',
      label_report_referral_credit: 'Referral Credit',
      label_report_passenger_feedback: 'Passenger Feedback',
      label_report__feedback: 'Feedback',
      label_mode_promo_code: 'Promo Code Mode',
      label_mode_usage_type: 'Usage Type',
      label_credit_amount: 'Credit Amount',
      label_referred_on: 'Referred on',
      label_clear_polygon: 'Clear Polygon',
      label_region_name: 'Region Name',
      label_alias_name: 'Alias Name',
      label_sr_no: 'Sr No.',
      label_view: 'View',
      label_contact_us: 'Contact Us',
      label_city_regions: 'City Regions',
      label_city_fares: 'City Fares',
      label_edit_fares: 'Edit Fares',
      label_cities: 'Cities',
      label_trip_fare: 'Actual Appointment Fare',
      label_qoutation_fare_estimate: 'Appointment Fare',
      label_estimated_trip_fare: 'Appointment Fare',
      label_estimated_fare: 'Appointment Fare',
      label_estimated_distance: 'Estimated Distance',
      label_estimated_time: 'Estimated Time',
      label_total_fares: 'Total Fare',
      label_estimated_total_fares: 'Total Fare',
      label_via_messageService: 'Via MessageService',
      label_service_message: 'Service Message',
      file_size_limit: 'This file is too large. The maximum size allowed is 20MB',
      file_size_limit_for_messages: 'File size should be less than 20MB',
      file_size_limit_5mb: 'The maximum size allowed is 5MB',
      invalid_file_type: 'Invalid file type',
      vehicle_assigned_already: 'Are you sure do you really want to assign this Provider? He is already assigned to other vehicle',
      driver_is_not_assigned: 'Provider is not assigned',
      label_ride_type: 'Ride Type',
      ride_type: 'Ride type is required',
      payment_mode_required: 'Payment mode is required',
      passenger_type_reqd: 'Passenger Type is required',
      driver_type_reqd: 'Provider Type is required',
      first_name_reqd: 'First Name is required',
      group_name_reqd: 'Group Name is required',
      assign_teams_reqd: 'Assign Teams is required',
      team_name_reqd: 'Team Name is required',
      vehicle_name_reqd: 'Vehicle Name is required',
      provider_name_reqd: 'Provider Name is required',
      last_name_reqd: 'Last Name is required',
      gps_id_reqd: 'GPS Id is required',
      label_vehicleNumber_reqd: 'Vehicle Number is required',
      lbl_pickup_location_eta_distance: 'Pick up ETA distance',
      lbl_pickup_location_eta_time: 'Pick up ETA time',
      lbl_drop_location_eta_distance: 'Drop up ETA distance',
      lbl_drop_location_eta_time: 'Drop up ETA time',
      lbl_passenger_name: 'Passenger Name',
      lbl_passenger_phone: 'Passenger Phone',
      lbl_passenger_email: 'Passenger Email',
      lbl_patient_name: 'Patient Name',
      lbl_patient_phone: 'Patient Phone',
      lbl_patient_email: 'Patient Email',
      label_estimated_trip_statistics: 'Estimated Appointment Statistics',
      label_estimated_fare_details: 'Estimated Fare Details',
      label_reassign: 'Reassign',
      label_center_name: 'Center Name',
      label_special_notes: 'Special Notes',
      label_other_details: 'Other Details',
      label_session_length: 'Session length',
      // USC : patient
      label_pharmacy_site: 'Pharmacy Site',
      label_patient_status: 'Patient Status',
      label_discharged_reason: 'Discharged Reason',
      label_other_discharged_reasons: 'Other Discharged Reasons',
      label_practitioner_type: 'Practitioner type',
      label_referral_source: 'Referral source',
      label_if_other: 'If other',
      label_street_address: 'Street address',
      label_medical_facility: 'Medical facility',
      label_notes: 'Notes',
      label_baseline_health_questions: 'Baseline Health Questions',
      label_height: 'Height',
      label_tobacco_status: 'Tobacco Status',
      label_current_tobacco_status: 'Current Tobacco Status',
      label_alcohol_consumption: 'Alcohol Consumption',
      label_caffeine_consumption: 'Caffeine Consumption',
      label_drinks: 'Drinks',
      label_recreationnal_drug_consumption: 'Recreational Drug Consumption',
      label_allergies: 'Allergies',
      label_family_history: 'Family History',
      label_relationship: 'Relationship',
      label_past_hospitalizations: 'Past Hospitalizations/ER Visits',
      label_chronic_Conditions_for_cmm: 'Chronic Conditions for CMM',
      label_other_medical_conditions: 'Other Medical Conditions',
      label_vaccinations: 'Vaccinations',
      label_vaccinations_date: 'Vaccination date',
      label_other_type: 'Other type',
      label_surgical_history: 'Surgical History',
      label_previous: 'Previous',
      label_add_new: 'Add New',
      label_identification: 'Identification',
      label_health_history: 'Health History',
      label_updated_by: 'Updated By',
      label_primary_referral_reason: 'Primary Referral Reason',
      label_visit_id: 'Visit ID',
      label_visit_date: 'Visit Date',
      label_visit_time: 'Visit Time',
      label_documented_by: 'Documented by',
      label_new_visit: 'New Visit',
      label_visit: 'Visit',
      label_weight: 'Weight',
      label_heart_rate: 'Heart Rate',
      label_plan_name: 'Plan name',
      label_firstname: 'First Name',
      label_lastname: 'Last Name',

      // menu handles
      menu_bookings: 'Bookings',
      menu_corporate_bookings: 'Corporate Bookings',
      menu_hotel_bookings: 'Hotel Bookings',
      menu_sales_quotation: 'Sales Quotations',
      menu_admin_dashboard: 'Dashboard',
      menu_corporate_dashboard: 'Corporate Dashboard',
      menu_passengers: 'Passengers',
      menu_dispatchers: 'Dispatchers',
      menu_vehicles: 'Vehicles',
      menu_drivers: 'Providers',
      menu_staff_transport: 'Staff Transport',
      menu_hotels: 'Hotels',
      menu_corporates: 'Corporates',
      menu_app_settings: 'App Settings',
      menu_settings: 'Settings',
      menu_notifications: 'App Notifications',
      menu_reports: 'Reports',
      menu_corporate_reports: 'Corporate Reports',
      menu_corporate_approval: 'Corporate Approval',
      menu_corporate_pending_approval: 'Pending for Approval',
      menu_corporate_manage_employee: 'Manage Employees',
      menu_corporate_manage_departments: 'Manage Departments',
      menu_corporate_settings: 'Corporate Settings',
      menu_manage_cities: 'Manage Cities',
      lebel_health_plan: 'Health Plan',
      lebel_health_plan_id: 'Health Plan ID',


      msg_success_summary: 'Success Message',
      msg_error_summary: 'Error Message',
      msg_warning_summary: 'Warning Message',
      msg_info_summary: 'Info Message',


      // app settings access
      access_get_privacy_policy: 'Get Privacy Policy',
      access_get_contact_support: 'Get Contact Support',
      access_get_rental_policy: 'Get Rental Policy',
      access_getChauffeur_policy: 'Get Chauffeur Policy',
      access_update_about_us: 'Update About Us',
      access_update_terms_and_conditions: 'Update Terms And Conditions',
      access_get_tenant_by_id: 'Get Tenant By ID',
      access_add_faq: 'Add FAQ',
      access_update_faq: 'Update FAQ',
      access_get_faq: 'Get FAQs',
      access_add_partner: 'Add Partner',
      access_update_partner: 'Update Partner',
      access_update_privacy_policy: 'Update Privacy Policy',
      access_delete_partner: 'Delete Partner',
      access_get_partners: 'Get Partners',
      acces_get_service_types: 'Get Service Types',
      access_get_labels: 'Get Labels',
      access_get_service_area: 'Get Service Area',
      access_get_terms_and_conditions: 'Get Terms And Conditions',
      access_get_about_us: 'Get About Us',
      access_udpate_rental_policy: 'Update Rental Policy',
      access_update_chauffer_policy: 'Update Chauffeur Policy',

      // Bookings accesses
      access_get_corporate_booking_grid: 'Get Corporate Bookings Grid',
      access_cancel_trip: 'Cancel Appointment',
      access_send_verification_code_to_passenger: 'Send Verification Code To Passenger',
      access_get_booking_bill_details: 'Get Booking Bill Details',
      access_add_driver_trip_emergency: 'Add Provider Appointment Emergency',
      access_add_passenger_trip_emergency: 'Add Passenger Appointment Emergency',
      access_get_reasons_by_type: 'Get Reasons By Type',
      access_get_corporate_pending_approval_grid: 'Get Corporate Pending Booking Approvals Grid',
      access_approve_booking: 'Approve Booking',
      access_get_booking_estimation: 'Get Booking Estimation',
      access_get_booking_details_by_trip_id: 'Get Booking Details By TripId',
      access_extend_booking_package: 'Extend Booking Package',
      access_udpate_rental_booking_security_checks: 'Update Rental Booking Security Checks',
      access_end_rental_booking: 'End Rental Booking',
      access_get_user_active_bookings: 'Get User Active Bookings',
      access_bookings_export_to_excel: 'Bookings Export To Excel',
      access_add_passenger_trip_rating: 'Add Passenger Appointment Rating',
      access_add_driver_trip_rating: 'Add Provider Appointment Rating',
      access_add_booking: 'Add Booking',
      access_add_corporate_booking: 'Add Corporate Booking',
      access_add_rental_booking: 'Add Rental Booking',
      access_add_chauffer_booking: 'Add Chaueffer Booking',
      access_get_passenger_bookings_list: 'Get Passenger Bookings List',
      access_get_bookings_by_user_type: 'Get Bookings By User Type',
      access_get_booking_grid: 'Get Booking Grid',
      access_bookings_export_to_pdf: 'Bookings Export To pdf',
      access_get_booking_details: 'Get Booking Details',
      access_cancel_booking: 'Cancel Booking',
      access_assign_driver: 'Assign Provider',
      access_acknowledge_booking_by_driver: 'Acknowledge Booking By Provider',
      access_accept_trip: 'Accept Appointment',
      access_start_trip: 'Start Appointment',
      access_end_trip: 'End Appointment',
      access_update_booking_bill_cash: 'Update Booking Bill Cash',
      access_update_booking_bill_credit_note: 'Update Booking Bill Credit Note',
      access_update_booking_driver_arrived: 'Update Booking Provider Arrived',
      access_add_trip_comment_reply: 'Add Appointment Comment Reply',
      access_get_note_by_trip_id: 'Get Note By TripId',
      resend_notification_message: 'Do you really want to resend the notification?',
      delete_faq: 'Are you sure you want to delete the FAQ?',
      delete_partner: 'Are you sure you want to delete this partner?',
      action_note1: 'Are you sure you want to ',
      corporate_action_note2: ' this Corporate?',
      dispatcher_action_note2: '  this dispatcher ?',
      invalid_corporate_message: 'Unable to load corporate details',
      edit_company_info: 'Edit Company Info',

      // corporate booking approval
      access_get_pending_approvals_grid: 'Get Pending Approvals Grid',
      access_approva_reject_corp_booking: 'Approve/Reject Corporate Booking',
      access_get_Corporate_booking_details: 'Get Corporate Booking Details',
      access_get_charge_codes_for_corporate: 'Get Charge Codes For Corporate',
      access_get_corporates_and_dept: 'Get Corporates And Departments',
      // corporate dashboard
      access_get_emergency_details: 'Get Emergency Details',
      access_get_corp_dash_emer_grid: 'Corporate Dashboard Emergencies Grid',
      access_get_corp_dash_graph: 'Get Corporate Dashboard Graph Data',
      access_get_corporate_dash: 'Get Corporate Dashboard Data',
      // corporate
      access_corp_count: 'Corporate Count',
      access_corp_search: 'Search Corporate',
      access_member_search: 'Search Patient',
      access_member_search_id: 'Search Patient by ID',
      access_member_search_neme: 'Search Patient by Name',
      access_driver_search_neme: 'Search Provider by Name',
      access_corp_export_to_pdf: 'Corporate Export To PDF',
      access_corp_export_to_xls: 'Corporate Export To Excel',
      access_corp_activate: 'Activate Corporate',
      access_corp_and_dept: 'Get Corporates And Departments',
      access_corp_dept_by_code: 'Get Corporate Departments By Corporate Code',
      access_corp_add: 'Add Corporate',
      access_corp_details: 'Get Corporate Details',
      access_corp_update: 'Update Corporate',
      access_corp_grid: 'Get Corporate Grid',
      access_corp_booking_history: 'Corporate Booking History',
      access_member_search_id_or_name: 'Search Patient by ID',
      // corporate settings
      access_corp_setting_get_charge_code: 'Get Charge Codes For Corporate',
      access_corp_setting_charge_code_grid: 'Charge Code Grid',
      access_corp_setting_add_chage_code: 'Add Charge Code',
      access_corp_setting_routes_grid: 'Routes Grid',
      access_corp_setting_partner_packge_grid: 'Partner Packages Grid',
      // dashboard
      access_admin_dash_emer_and_booking_details: 'Emergency and booking details',
      access_admin_dash_emer_grid: 'Emergency Grid',
      access_admin_dash_get: 'Get Dashboard Data',
      access_admin_dash_get_graph: 'Get Dashboard Graph Data',
      label_manual_bookings: 'Manual Bookings',
      label_manual_booking_count: 'Total Manual Bookings',
      label_app_bookings: 'App Bookings',
      label_count_app_bookings: 'Total App Bookings',
      // dispatchers
      access_disp_get_details: 'Get Dispatcher Details',
      access_disp_update: 'Update Dispatcher',
      access_disp_export_to_pdf: 'Dispatchers Data For Export To PDF',
      access_disp_export_to_xls: 'Dispatchers Data For Export To Excel',
      access_disp_get_count: 'Get Dispatchers Count',
      access_disp_grid: 'Dispatchers Grid',
      access_disp_search: 'Search Dispatchers',
      access_disp_get_booking_history: 'Get Dispatcher Booking History',
      access_disp_get_roles: 'Get Dispatcher Roles',
      access_disp_activate_deactivate: 'Activate/Deactivate Dispatcher',
      access_disp_add: 'Add Dispatcher',
      // providers
      access_driver_get: 'Get Provider Info',
      access_driver_update: 'Update Provider',
      access_driver_search: 'Search Provider',
      access_driver_activate_deactivate: 'Activate/Deactivate Provider',
      access_driver_grid: 'Providers Grid',
      access_driver_export_to_xls: 'Providers Export To Excel',
      access_driver_export_to_pdf: 'Providers Export To PDF',
      access_driver_get_count: 'Get Providers Count',
      access_driver_get_vehicle_and_assigne: 'Get Vehicle Assigned To Provider',
      access_driver_update_duty_status: 'Update Provider Duty Status',
      access_driver_get_near_by: 'Get Near By Providers',
      access_driver_get_booking_history: 'Get Provider Booking History',
      access_driver_get_location: 'Get Location Of Provider',
      access_driver_get_docs_types: 'Get Provider Document Types',
      access_driver_add: 'Add Provider',
      label_service_cities: 'Service Cities',
      label_service_city: 'Service City',
      // hotel bookings
      access_hotel_bookings_grid: 'Hotel Bookings Grid',
      access_hotel_bookings_add: 'Add Hotel Bookings',
      access_hotel_bookings_export_to_xls: 'Hotel Bookings Export To Excel',
      access_hotel_bookings_export_to_pdf: 'Hotel Bookings Export To PDF',
      access_hotel_bookings_list: 'Get All Hotels List',
      // hotels
      access_hotels_activate: 'Activate/Deactivate Hotel',
      access_hotels_booking_grid: 'Get Hotel Booking History Grid',
      access_hotels_grid: 'Hotels Grid',
      access_hotels_export_to_xls: 'Hotels Export To Excel',
      access_hotels_export_to_pdf: 'Hotels Export To PDF',
      access_hotels_get_count: 'Get Hotels Count',
      access_hotels_search: 'Search Hotel',
      access_hotels_add: 'Add Hotel',
      access_hotels_details: 'Get Hotel Details',
      access_hotels_update: 'Update Hotel',
      // manage departments
      access_dept_update: 'Update Department',
      access_dept_add: 'Add Department',
      access_dept_export_to_xls: 'Departments Grid Export To Excel',
      access_dept_grid: 'Departments Grid',
      access_dept_search: 'Search Employee',
      // manage employee
      access_emp_add: 'Add Employee',
      access_emp_trip_history_grid: 'Employee Appointment History Grid',
      access_emp_set_vip: 'Set Employee As VIP',
      access_emp_reject: 'Reject Employee',
      access_emp_approve: 'Approve Employee',
      access_emp_export_to_xls: 'Employees Grid Export To Excel',
      access_emp_activate: 'Activate/Deactivate Employee',
      access_emp_for_pending_approval: 'Get Employee For Pending Approval',
      access_emp_details: 'Get Employee Details',
      access_emp_udpate: 'Update Employee',
      access_emp_grid: 'Employees Grid',

      // notifications
      access_noti_send: 'Send Notification',
      access_noti_delete_of_user: 'Delete Notifications Of User',
      access_noti_delete: 'Delete Notification',
      access_noti_mark_read: 'Mark Notification Read',
      access_noti_send_status: 'Send Notification Status',
      access_noti_get_list: 'Get Notifications List',

      // passengers
      access_passengers_get_details: 'Get Passenger Details',
      access_passengers_get_trip_history: 'Get Appointment History For Passenger',
      access_passengers_get_by_type: 'Get Passengers By Type',
      access_passengers_search: 'Search Passengers',
      access_passengers_grid: 'Passenger Grid',
      access_passengers_get_count: 'Get Passenger Count',
      access_passengers_export_to_xls: 'Passenger Data Export To Excel',
      access_passengers_export_to_pdf: 'Passenger Data Export To PDF',
      access_passengers_activate: 'Active/Deactive Passenger',
      // sales quotations
      access_sales_quo_accept_reject: 'Accept/Reject Sales Quotation',
      access_sales_quo_upload: 'Upload Sales Quotation',
      access_sales_quo_details: 'Sales Quotation Details',
      access_sales_quo_grid: 'Sales Quotations Grid',
      access_sales_quo_cancel: 'Cancel Sales Quotation',
      access_sales_quo_export_to_xls: 'Sales Quotations Export To Excel',
      access_sales_quo_export_to_pdf: 'Sales Quotations Export To PDF',
      access_sales_quo_view_quotations_details: 'View Quotation Details',

      // staff transport
      access_staff_update: 'Update Staff Transport',
      access_staff_activate: 'Activate/Deactivate Company',
      access_staff_get_drop_points: 'Get Staff Transport Vehicle Drop Points',
      access_staff_add: 'Add Staff Transport',
      access_staff_grid: 'Staff Transport Grid',
      access_staff_details: 'Get Staff Transport Details',
      access_staff_activate_vehicle: 'Activate/Deactivate Staff Transport Vehicle',
      access_staff_count: 'Get Staff Transport Count',
      access_staff_add_booking: 'Add Booking For Staff Transport',
      access_staff_export_to_xls: 'Staff Transport Export To Excel',
      access_staff_export_to_pdf: 'Staff Transport Export To PDF',
      access_staff_update_status: 'Update Staff Transport Status',
      access_staff_renew_package: 'Renew Staff Transport Package',

      // vehicles
      access_vehicle_get_by_type: 'Get Vehicle By Vehicle Type',
      access_vehicle_get_types: 'Get Vehicle Types',
      access_vehicle_get_service_type: 'Get Service Types',
      access_vehicle_activate: 'Activate/Deactivate Vehicle',
      access_vehicle_grid: 'Vehicles Grid',
      access_vehicle_get_docs_types: 'Get Vehicle Document Types',
      access_vehicle_add: 'Add Vehicle',
      access_vehicle_get_count: 'Get Vehicle Counts',
      access_vehicle_update: 'Update Vehicle',
      access_vehicle_get_details: 'Get Vehicle Details',
      access_vehicle_export_to_xls: 'Vehicles Data Export To Excel',
      access_vehicle_export_to_pdf: 'Vehicles Data Export To PDF',
      access_vehicle_search: 'Search Vehicle',
      access_vehicle_near_by_vehicles: 'Get Near By Vehicles',
      access_vehicle_get_driver_info_by_vehicle: 'Get Provider Info By Vehicle',
      access_vehicle_get_near_by_vehicles_by_area: 'Get Near By Vehicles By Area',
      access_vehicle_booking_history_grid: 'Vehicle Booking History Grid',
      access_vehicle_add_type: 'Add Vehicle Type',
      access_vehicle_update_type: 'Update Vehicle Type',
      access_vehicle_type_grid: 'Vehicle Types Grid',
      access_vehicle_activate_type: 'Activate/Deactivate Vehicle Type',
      cnf_deactivate_city_then_all_driver_not_receive_request:
        'If you deactivate city then all providers attached to this city will not receive any appointment requests',
      cnf_realy_want_to: 'Are you sure you want to ',
      cnf_this_area: ' this area?',
      cnf_this_company: ' this company?',
      cnf_this_region: ' this region?',

      // cities
      label_city_name: 'City Name',
      lable_alias_name: 'Alias Name',
      label_add_city: 'Add City',
      label_city_name_msg: 'City Name required',
      label_alias_msg: 'Alias Name required',
      label_last_hour: 'Last Hour',
      label_last_4_hours: 'Last 4 Hours',
      label_current_day: 'Current Day',
      label_last_7_days: 'Last 7 Days',
      label_last_15_days: 'Last 15 Days',
      label_last_30_days: 'Last 30 Days',
      label_from_custom_date: 'Custom Date',
      label_today: 'Today',
      label_yesterday: 'Yesterday',
      label_this_month: 'This Month',
      label_last_month: 'Last Month',
      label_export: 'Export',
      label_total_trips_cancelled_by_drivers: 'Total Appointments Cancelled By Providers',
      label_cancelled_by: 'Cancelled By',
      label_total_trips: 'Total Appointments',
      label_trips: 'Appointments',
      label_total_trips_cancelled_by_passengers: 'Total Appointments Cancelled By Passengers',
      label_total_drivers_on_platform: 'Total Providers On Platform',
      label_drivers_received_trips: 'Providers Received Appointments',
      label_total_drivers_payout: 'Total Providers Payout',
      label_total_platform_commission: 'Total Platform Commission',
      label_total_receivable_from_drivers: 'Total Receivable From Providers',
      label_total_promo_discount: 'Total Promo Discount',
      label_total_refunded_amount: 'Total Refunded Amount',
      label_view_report: 'View Report',
      label_role_name: 'Role Name',
      role_name_pattern: 'Invalid role name',
      label_role_code: 'Role Code',
      label_user_code: 'User Code',
      label_user_name: 'User Name',
      label_tax_name: 'Tax Name',
      label_tax_percentage: 'Tax Percentage',
      label_free_distance: 'Free Distance',
      label_free_distance_placeholder: 'Free Distance (KM) *',
      free_dist_req: 'Free distance is required',
      label_per_distance_fare: 'Per Miles Fare',
      label_per_distance_fare_placeholder: 'Per KM Fare',
      label_per_Mile_fare: 'Per Mile Fare',
      label_per_min_fare: 'Per min. Fare',
      label_per_min_fare_placeholder: 'Per min. Fare *',
      label_driver_percentage: 'Provider Percentage',
      label_driver_percentage_placeholder: 'Provider Percentage *',
      label_contactNumber: 'Contact Number',
      label_supportEmail: 'Support Email',
      label_minimum_fare: 'Minimum Fare',
      label_minimum_fare_placeholder: 'Minimum Fare *',
      minimum_fare_req: 'Minimum fare is required',
      label_referrer_benefit: 'Referrer Benefit',
      label_referee_benefit: 'Referee Benefit',
      have_promo_code: 'Have A Promotional Code?',
      label_manual_booking: 'Manual Booking',
      label_book_now: 'Book Now',
      label_book_ride_now: 'Book Ride Now',
      label_book_ride_later: 'Book Ride Later',
      label_book_ride_share: 'Book Ride Share',
      label_ride_share: 'Ride Share',
      label_car_details: 'Car Details',
      label_trip_details: 'Appointment Details',
      label_car_type_with_colon: 'Car Type :-',
      label_model_with_colon: 'Model Name :-',
      label_vehicle_make_with_colon: 'Vehicle Make :-',
      label_color_with_colon: 'Color :-',
      label_vehicle_type_with_colon: 'Vehicle Type :-',
      label_plate_number_with_colon: 'Plate Number :-',
      label_status_with_colon: 'Status',
      label_email_text: 'Email',
      label_color: 'Color',
      label_refund: 'Refund',
      label_rider_details: 'Rider Details',
      label_vehicle_details: 'Vehicle Details',
      label_shift_details: 'Shift Details',
      label_rating_given_by_driver: 'Ratings given by provider',
      label_rating_given_by_passenger: 'Ratings given by passenger',
      label_fare_breakdown: 'Fare Breakdown',
      label_trip_statistics: 'Appointment Statistics',
      label_distance_fare_text: 'Distance fare',
      label_time_fare: 'Time fare',
      label_total: 'Total',
      label_duration: 'Duration',
      label_total_time: 'Total time',
      label_email_invoice: 'Email Invoice',
      label_invoice_receipt: 'Invoice Receipt',
      label_cash_collected: 'Cash Collected',
      label_profile_password: 'Profile Password',
      label_edit_profile: 'Edit Profile',
      label_password: 'Password',
      label_enter_password: 'Enter password',
      label_profile_password_required: 'Profile password is required',
      label_invalid_amount: 'Invalid amount',
      refund_amount_required: 'Refund amount is required',
      label_free_cars: 'Free Vehicles',
      label_busy_cars: 'Busy Vehicles',
      label_idle_cars: 'Idle Vehicles',
      label_no_nearby_driver: 'It seems there are no nearby providers available',
      label_tour_time_fare: 'Appointment Time Fare',
      label_enroute_waiting_fare: 'Enroute Waiting Fare',
      label_manage_taxes: 'Manage Taxes',
      label_add_tax: 'Add Tax',
      label_update_tax: 'Update Tax',
      label_app_store_url: 'App Store URL',
      label_play_store_url: 'Play Store URL',
      label_facebook_url: 'Facebook URL',
      label_twitter_url: 'Twitter URL',
      label_google_plus_url: 'Google Plus URL',
      label_number_of_seats: 'Number Of Seats',
      label_number: 'Number',
      label_additional_seat_fare: 'Additional Seat Fare',
      label_ride_share_service: 'Ride Share Service',
      label_profile_updated_successfully: 'User profile is updated Successfully',
      label_load_more_notifications: 'Load More Notifications',
      label_show_more: 'Show More..',
      label_map: 'View Map',
      label_users: 'Users',
      label_helpline: 'Helpline',
      label_heat_map: 'Heat Map',
      label_plateform_summary_report: 'Dashboard',
      label_data_charts: 'Data Charts',
      label_emergency: 'Emergency',
      label_driver_emergency: 'Provider Emergency',
      label_passenger_emergency: 'Passenger Emergency',
      label_member_emergency: 'Patient Emergency',
      label_by_drivers: 'By Providers',
      label_by_members: 'By Patients',
      label_new_request: 'New Request',
      label_attened: 'Attended',
      label_attend_it: 'Attend It',
      label_closed: 'Closed',
      label_close_it: 'Close It',
      label_request_status: 'Request Status',
      label_arrange_vehicles: 'Arrange Vehicles',
      label_all_vehicle_type: 'All Vehicle Type',
      label_selected_vehicle_type: 'Selected Vehicle Type',
      label_company_name: 'Company Name',
      label_company: 'Company',
      company_pattern: 'Invalid company name',
      label_add_company: 'Add Company',
      label_edit_company: 'Edit Company',
      label_change_password: 'Change Password',
      label_appointment_type: 'Appointment Type',
      label_cancelled_trip_reason: 'Cancelled Appointment Reason',
      label_appointment_reason: 'Appointment Reason',
      label_member_requirment: 'Patient Requirement',
      label_member_details: 'Patient Details',
      label_additional_notes: 'Instructions',
      label_recurrence_pattern: 'Recurrence Pattern',
      label_range_of_recurrence: 'Range of recurrence',
      label_additional_notes_placeholder: 'Ex: Drive slow',
      label_old_password: 'Old Password *',
      label_new_password: 'New Password *',
      label_new_confirm_password: 'Confirm New Password *',
      label_otp_send_text: 'Please enter OTP which has been sent to your Email ID',
      label_add_user: 'Add User',
      label_edit_user: 'Edit User',
      label_add_operator: 'Add Operator',
      label_edit_operator: 'Edit Operator',
      label_operator_name: 'Operator Name',
      label_reset_password: 'Reset Password',
      label_select_no_of_seats: 'Select Number Of Seat(s)',
      label_vehicle_assigned: 'Vehicle Assigned',
      label_assigned: 'Assigned',
      label_non_assigned: 'Non-Assigned',
      label_unassigned: 'Unassigned',
      label_admin_settlement: 'Admin Settlement',
      label_promo_discount: 'Promo Discount',
      label_credits: 'Credits',
      label_percentage: 'Percentage',
      label_driver_amount: 'Provider Amount',
      label_date: 'Date',
      label_time: 'Time',
      label_bid_amount: 'Bid Amount',
      label_driver_status: 'Provider Status',
      label_passenger_status: 'Passenger Status',
      label_bidding_details: 'Bidding Details',
      label_total_invoice: 'Total Invoice',
      label_booking_fares: 'Booking Fare',
      label_total_distance: 'Total Distance',
      label_total_driver: 'Total Providers',
      label_active_driver: 'Active Providers',
      label_inactive_driver: 'Inactive Providers',
      label_offline_driver: 'Offline Providers',
      label_online_driver: 'Online Providers',
      label_doc_expiring: 'Docs Expiring In 7 Days',
      label_total_passengers: 'Total Passengers',
      label_online_passengers: 'Online Passengers',
      label_online_vehicles: 'Online Vehicles',
      label_total_dispatchers: 'Total Dispatchers',
      label_online_dispatchers: 'Online Dispatchers',
      label_total_hotels: 'Total Hotels',
      label_online_hotels: 'Online Hotels',
      label_total_companies: 'Total Companies',
      label_online_companies: 'Online Companies',
      label_total_corporates: 'Total Corporates',
      label_online_corporates: 'Online Corporates',
      label_ride_share_name: 'Ride Share Name',
      label_source_radius: 'Source Radius(miles)',
      label_destination_radius: 'Destination Radius(miles)',
      label_per: 'Per',
      label_source_radius_dynamic: 'Source Radius',
      label_destination_radius_dynamic: 'Destination Radius',
      label_no_of_drivers: 'No. of Providers',
      label_create_segment: 'Create Segment',
      label_create_notification: 'Create Notification',
      label_segment_name: 'Segment Name',
      label_segment: 'Segment',
      label_created_date_time: 'Created Date & Time',
      label_created_date_and_time: 'Created Date and Time',
      label_created: 'Created',
      label_contact_match: 'Contact match your condition',
      label_number_of_users: 'Number Of Users',
      label_role_type: 'Role Type',
      label_radius_area: 'KM *',
      label_near_by_driver: 'Near by Providers',
      label_user: 'User',
      label_segment_required: 'Segment Name is required',
      label_send_notification: 'Send Notification',
      label_message_type: 'Message Type',
      label_select_message_type: 'Select Message Type',
      label_message: 'Message',
      label_message_title: 'Message Title',
      label_title: 'Title',
      label_segment_list: 'Segment List',
      label_select_segment: 'Select Segment',
      label_add_new_segment: 'Add New Segment',
      label_message_description: 'Message Description',
      label_message_description_place: 'Message Description (Maximum characters limit: 150)',
      label_message_email_description_place: 'Message Description (Maximum characters limit: 150)',
      label_add_segment: 'Add Segment',
      mins_required: 'Minutes required',
      secs_required: 'Seconds required',
      label_promocode_pattern: 'Promocode should be in capital and numbers only',
      label_driver_information: 'Provider Information',
      label_vehicle_information: 'Vehicle Information',
      label_member_information: 'Patient Information',
      label_distance_travelled_miles: 'Distance Travelled',
      label_distance_travelled_miles_KM: 'Distance Travelled( KM )',
      label_fare_details: 'Fare Details',
      label_current_driver_online: 'Current Provider Online',
      label_valid_date_range: 'Please Select valid Date range for reports',
      label_offline: 'Offline',
      label_vehicle_make_model: 'Vehicle make & model',
      label_driver_required: 'Provider required',
      label_drop_trip: 'Drop Appointment',
      label_drop_time_required: 'Drop time required',
      label_total_time_in_minuites: 'Appointment Time',
      label_no_route_available: 'No Route Available',
      label_referral: 'Referral',
      label_referral_code: 'Referral Code',
      label_wallet_amount: 'Wallet Amount',
      label_trip_note: 'Appointment Note',
      label_add_note: 'Add Note',
      label_wallet_update: 'Wallet Update',
      label_amount_required: 'Amount required',
      label_amount_limit_exceeded: 'Amount limit exceeded',
      label_wallet: 'Wallet',
      label_referral_credit: 'Referral Credit',
      label_payment_type: 'Payment Type',
      label_tour_start_date_time: 'Tout Start Date & Time',
      label_tour_end_date_time: 'Tout End Date & Time',
      label_tour_status: 'Appointment Status',
      label_service_type_code: 'Service Type Code',
      lbl_credit_card_info: 'Card Info',
      label_sub_total: 'Sub Total',
      label_tax_amount: 'Tax Amount',
      label_grand_total: 'Grand Total',
      label_vehicle_condition: 'Vehicle condition',
      label_company_percentage: 'Company Percentage',
      label_company_percentage_placeholder: 'Company Percentage *',
      label_total_completed_trips: 'Total completed Appointments',
      label_total_invoice_amount: 'Total Invoice Amount',
      label_company_share: 'Company Share',
      label_admin_share: 'Admin Share',
      label_add_vehicle: 'Add Vehicle',
      label_add_driver: 'Add Provider',
      label_new_provider: 'New Provider',
      label_remove_vehicle: 'Remove Vehicle',
      label_add_vehicle_description: 'Please select one or more vehicle(s)',
      label_view_assign_vehicle: 'View Assigned Vehicle',
      label_vehicle_not_found: 'No assigned vehicle found for this provider',
      label_draw_region_on_map: 'Please draw region on map.',
      label_select_valid_app_user: 'Please select valid app user.',
      label_fillup_guest_user_details: 'Please fill up guest user details.',
      label_select_driver_from_driver_list: 'Please select provider from provider list.',
      label_add_provider: 'Add Provider',
      label_provider_type: 'Provider Type',
      label_dispatch_type: 'Dispatch Type',
      label_providers_details: 'Providers Details',
      label_teams_details: 'Team Details',
      label_teams_edit_details: 'Edit Shift Plan',
      label_teams_add_details: 'Add Shift Plan For',
      label_teams_delete_details: 'Delete Shift Plan For',
      label_manage_teams: 'Manage Teams',
      label_teams_name: 'Teams Name',
      label_team_members: 'Team Members',
      label_search_team_members: 'Search by Team Member',
      label_search_team_web: 'Search by Team Member',
      label_edit_provider: 'Edit Provider',
      label_add_new_provider: 'Add New Provider',
      label_edit_group: 'Edit Group',
      label_add_new_group: 'Add New Group',
      label_add_new_helpline: 'Add Helpline Numbers',
      label_edit_helpline: 'Edit Helpline Numbers',
      label_edit_team: 'Edit team',
      label_add_new_team: 'Add New Team',
      label_edit_vehicle: 'Edit Vehicle',
      label_add_new_vehicle: 'Add New Vehicle',
      label_add_team_member: 'Add Team Member',
      label_edit_team_member: 'Edit Team Member',
      label_add_new_user: 'Add New User',
      label_team_availability: 'Team Availability',
      label_availability: 'Availability',
      label_teams: 'Teams',
      label_team: 'Team',
      label_groups: 'Groups',
      label_gps_id: 'GPS ID',
      label_specialization: 'Specialization',
      label_market_nane: 'Market Name',
      label_market: 'Market',
      label_primary_market: 'Primary Market',
      label_default_timezone: 'Default Timezone',
      label_associated_markets: 'Associated Markets',
      label_saved_appointment_filter: 'Appointment filter is saved Successfully.',
      label_reset_appointment_filter: 'Appointment filter is reset Successfully.',
      label_close: 'Close',
      label_add_market: 'Add Market',
      label_update_market: 'Update Market',
      label_market_name: 'Market Name',
      label_timezone_name: 'Timezone Name',
      label_timezone: 'Timezone',
      label_abbr: 'Abbreviation',

      // Error messages
      invalid_input: 'Invalid input',
      min_length_phone_number: 'Phone number should contain minimum 10 digit',
      min_length_phone_number_digit: 'Phone number should contain minimum 3, 10 or 11 digit',
      min_length_mobile_number: 'Mobile number should contain minimum 10 digit',
      max_length_mobile_number: 'Mobile number should contain maximum 10 digit',
      max_length_phone_number: 'Phone number should contain maximum 10 digit',
      max_address: 'Maximum 100 characters allowed',
      max_trip_node: 'Maximum 500 characters allowed',
      max_length_3: 'You have exceeded the maximum 3 digit limit',
      max_length_special_note: 'You have exceeded the maximum 200 character limit',
      max_number_10: 'You have exceeded the maximum 10 numbers limit',
      max_number_1: 'You have exceeded the maximum 1 numbers limit',
      max_number_20: 'You have exceeded the maximum 20 numbers limit',
      max_character_10: 'You have exceeded the maximum 10 character limit',
      max_character_20: 'You have exceeded the maximum 20 character limit',
      max_character_19: 'You have exceeded the maximum character limit',
      max_character_50: 'You have exceeded the maximum 50 character limit',
      max_character_100: 'You have exceeded the maximum 100 character limit',
      max_character_200: 'You have exceeded the maximum 200 character limit',
      max_character_300: 'You have exceeded the maximum 300 character limit',
      max_character_500: 'You have exceeded the maximum 500 character limit',
      max_character_err: 'You have exceeded the maximum character limit',

      file_upload_error: 'Entity is too large to upload',
      error_region_name_required: 'Region Name is required',
      error_country_code: 'Please select country code',
      error_alias_name_required: 'Alias Name is required',
      error_cities_required: 'City is required',
      error_service_area_required: 'Service area is required',
      error_base_fare_required: 'Base fare is required',
      error_invalid_base_fare: 'Invalid base fare',
      error_booking_fee_required: 'Booking fee is required',
      error_invalid_booking_fee: 'Invalid booking fee',
      error_ride_time_fare_required: 'Ride time fare is required',
      error_invalid_ride_time_fare: 'Invalid ride time fare',
      error_vehicle_required: 'Vehicle is required',
      error_about_us_required: 'About us is required',
      error_model_name_required: 'Model name is required',
      error_model_color_required: 'Model color is required',
      error_vehicle_no_required: 'License plate number is required',
      error_vehicle_reg_no_required: 'Vehicle Registration Number is required',
      error_document_id_required: 'Document ID is required',
      error_invalid_fax: 'Fax number is invalid',
      error_max_length_excced: 'Character max length exceed',
      error_email_id_required: 'Email is required',
      error_vehicle_make_required: 'Vehicle make is required',
      error_number_of_seats_required: 'Number of seats is required',
      error_invalid_number_of_seats: 'Invalid number of seats',
      error_additional_seat_fare_required: 'Additional seat fare is required',
      error_invalid_additional_seat_fare: 'Invalid additional seat fare',
      error_mode_required: 'Mode is required',
      error_usage_required: 'Usage is required',
      error_limit_of_uses_required: 'No of uses is required',
      error_company_required: 'Company is required',
      error_company_name: 'Company name is required',
      error_old_password_required: 'Old password is required',
      error_new_password_required: 'New password is required',
      error_new_confirm_password_required: 'Confirm new password is required',
      error_new_confirm_password_not_match: 'New and confirmed password do not match',
      error_account_confirm_account_not_match: 'Account number and confirm account number are not matched',
      error_similar_location_name: 'Pickup location and Drop location cannot be the same',
      error_from_date_required: 'From date is required',
      error_invalid_date: 'Invalid Date & time',
      error_quotation_amount: 'please enter quotation amount',
      error_quotation_note: 'Please enter quotation note',
      error_quotatin_file: 'please upload quotation',
      error_vehicle_type_required: 'Vehicle type is required',
      error_trip_type_required: 'Appointment type is required',
      error_member_required: 'Patient ID is required',
      error_invalid_patient_id: 'Invalid patient ID',
      error_invalid_mrn_id: 'Invalid Client Case No.',
      error_appointment_type_required: 'Appointment type is required',
      error_select_one: 'Please select one',
      error_appointment_type_reason_required: 'Appointment reason is required',
      error_member_requirement_required: 'Please select one Patient',
      error_additional_note_required: 'Additional note is required',
      error_vehicle_name_required: 'Vehicle name is required',
      error_languageCode_required: 'Language is required',
      error_recurrence_week_max_min: 'Range must be 1 - 9',
      error_recurrence_max_min: 'Range must be 1 - 99',
      error_to_date_required: 'To date is required',
      error_invalid_access_group: 'No access group assigned to the user',
      error_fetch_meta_data: 'Error occured during fetching user meta data',
      error_invalid_email: 'Email is invalid',
      error_invalid_phone_number: 'Phone number is invalid',
      error_invalid_country_code: 'Country code is invalid',
      error_enroute_waiting_fare_required: 'Enroute waiting fare is required',
      error_invalid_enroute_waiting_fare: 'Enroute waiting fare is invalid',
      error_free_distance_required: 'Free distance is required',
      error_invalid_free_istance: 'Invalid free distance',
      error_source_radius_required: 'Source radius is required',
      error_invalid_source_radius: 'Source radius is invalid',
      error_destination_radius_required: 'Destination radius is required',
      error_invalid_destination_radius: 'Destination radius is invalid',
      error_ride_share_name_required: 'Ride share name is required',
      error_per_km_fare_required: 'Per km fare is required',
      error_invalid_per_km_fare: 'Invalid per km fare',
      error_per_miles_fare_required: 'Per miles fare is required',
      error_invalid_per_miles_fare: 'Invalid per miles fare',
      error_invalid_per_mile_mile_fare: 'Invalid per mile fare',
      error_per_minute_fare_required: 'Per min fare is required',
      error_invalid_per_minute_fare: 'Invalid per min fare',
      error_wait_time_fare_required: 'Wait time fare is required',
      error_invalid_wait_time_fare: 'Invalid wait time fare',
      error_cancellation_charges_required: 'Cancellation charges is required',
      error_invalid_cancellation_charges: 'Invalid cancellation charges',
      error_driver_percentage_required: 'Provider percentage is required',
      error_invalid_driver_percentage: 'Invalid provider percentage',
      error_minimum_fare_required: 'Minimum fare is required',
      error_invalid_minimum_fare: 'Invalid minimum fare',
      error_tour_time_fare_required: 'Appointment time fare is required',
      error_invalid_tour_time_fare: 'Invalid appointment time fare',
      error_vehicle_icon_required: 'Vehicle icon is required',
      error_role_type_required: 'Role type is required',
      error_status_required: 'Status is required',
      error_radius_required: 'Radius is required',
      error_radius_area_required: 'Radius area is required',
      error_description_required: 'Description is required',
      error_user_required: 'User is required',
      error_segment_list_required: 'Segment is required',
      error_message_type_required: 'Message type is required',
      error_message_title_required: 'Message type is required',
      error_message_description_required: 'Message description required',
      error_select_date_range: 'Please select date range',
      error_valid_parameter_to_view_export: 'Please select any valid parameter to view exported file',
      error_service_city_required: 'Service city is required',
      error_getting_platform_summery_data: 'Error occured during getting platform summary data',
      error_getting_heat_map_data: 'Error occured during getting heat map data',
      error_role_required: 'Role name is required',
      error_service_region_required: 'Service region name is required',
      error_service_locations_required: 'Service Locations name is required',
      error_first_name_required: 'First name is required',
      error_last_name_required: 'Last name is required',
      error_tax_name_required: 'Tax name is required',
      error_tax_percentage_required: 'Tax percentage is required',
      error_invalid_tax_percentage: 'Invalid tax percentage',
      error_per_Mile_fare_required: 'Per mile fare is required',
      error_password_required: 'Password is required',
      error_company_name_required: 'Company name is required',
      error_company_website_required: 'Company website is required',
      error_password_pattern: `Password should be minimum 8 characters and must include a combination of
      uppercase and lowercase alphabets, symbols and numbers. Example: Test@1234`,
      error_invalid_company_website: 'Invalid company website',
      error_invalid_website_url: 'Invalid website URL',
      error_address_1_required: 'Address 1 is required',
      error_vehice_is_requied: 'Vehicle is required',
      error_not_exist_page: 'The page you are looking is not exist. Please contact to your Admin.',
      error_max_provider_selection_limit: 'You can select upto maximum 5 providers.',
      error_market_name_required: 'Market name is required',
      error_market_timezone_required: 'Timezone name is required',
      error_timezone_abbr_required: 'Abbreviation is required',

      label_pu_addr: 'PU Addr',
      label_do_addr: 'DO Addr',
      label_pu_time_date: 'PU Time',
      label_appt_time: 'Appt time',
      label_estimated_distance_time: 'Estimated Distance And Time',
      label_trip_by_appointment_type: 'Appointments by Appointment Type',
      label_import_data: 'Import Appointment Data',
      label_import_providers: 'Import Providers',
      label_import_provider_availability: 'Import Provider Availability',
      label_remote_provider_timeslot_availabile: 'Remote provider timeslot available.',
      label_onsite_provider_timeslot_availabile: 'Onsite provider timeslot available.',
      lbl_view_schedule: 'View Schedule',
      label_import_for: 'Import For',
      label_appointment_ended: 'Appointment session ended successfully.',
      label_appointment_started: 'Appointment session started successfully.',
      label_no_helpline_details: 'No helpline details are available',

      label_new_sub_category: 'Add New Sub-Category',
      label_update_sub_category: 'Update Sub-Category Details',
      label_cat_name: 'Category Name',
      label_subcat_name: 'Sub-Category Name',
      cat_name_req: 'Category Name is required',
      subcat_name_req: 'Sub-Category Name is required',
      que_name_req: 'Question is required',
      label_new_form: 'Add New Form',
      label_update_form: 'Update Form',
      label_form_name: 'Form Name',
      form_name_req: 'Form Name is required',
      label_formtype: 'Form Type',
      label_assignee: 'Assignee',
      select_category: 'Select Category',
      invalid_category_pattern: 'Invalid Category Name',
      invalid_subcategory_pattern: 'Invalid Sub-Category Name',
      invalid_form_pattern: 'Invalid Form Name',

      label_book_dual_appointment: 'Book Dual Appointment',
      label_confirm_dual_booking: 'Request added successfully',
      label_confirm_dual_booking_msg: 'Your appointment request is successfully added in the system. Please wait while the supervisor approves your request.',
      label_pending_approval_lbl: 'Pending Approval',

      label_email_notifications: 'Email Notifications',
      label_sms_notifications: 'SMS Notifications',

      label_education_category_type: 'Category Type',
      label_patient_complete_by: 'Complete By',
      label_education_role_filter: 'Role',

      education_title_req: 'Education Material Title is required',
      education_type_req: 'Education Material Type is required',
      education_access_req: 'Education Material Access is required',
      education_link_req: 'Education Material Link is required',
      education_category_req: 'Education Material Category Type is required',
      invalid_youtube_link: 'Invalid youtube link',

      label_document_name: 'Document',
      label_document_sent_by: 'Sent By',
      label_document_role: 'Role',
      label_document_date: 'Date',
      label_document_time: 'Time',
      label_document_filetype: 'File Type',
      label_choose_document_file: 'Upload Document',

      label_preview: 'Preview',

      label_master_forms: 'Masters - Forms',
      label_screening: 'Screening',
      label_plan_of_Care: 'Plan of Care',
      label_assessment: 'Assessment',
      label_feedback_Survey: 'Feedback and Survey',
      label_permissions: 'Permissions',
      label_admin: 'Admin',
      label_before_appointment: 'Before Appointment Creation',
      label_after_appointment: 'After Appointment Creation',
      label_instructions: 'Instructions',
      label_form_description: 'Description',
      label_patient: 'Patient',
      label_remote_provider: 'Remote Provider',
      label_onsite_provider: 'Onsite Provider',
      label_form_assignee: 'Assignee',
      label_staff_member: 'Staff Member',
      label_questions: 'Questions',
      label_add_new_question: 'Add New Question',
      label_add_new_section: 'Add New Section',
      label_question_txt: 'Question',
      label_program_type: 'Program Type',
      label_patient_condition: 'Patient Condition',
      label_applicable_event: 'Applicable Event',
      label_section_name: 'Section Name',
      label_independent: 'Independent',
      label_appointment: 'Appointment',
      label_select_appointment_type: 'Appointment Type',
      label_add_option: 'Add Option',
      label_add_other: 'Add Other',
      label_or_txt: 'Or',
      label_to_txt: 'to',
      label_grid_rows: 'Rows',
      label_grid_columns: 'Columns',
      label_add_rows: 'Add Row',
      label_add_columns: 'Add Column',
      label_subjective: 'Subjective Questions',
      label_select_date: 'Select Date',
      label_form_description_footer: 'Description Footer',
      label_forms_details: 'Forms Details',
      form_title_req: 'Form name required',
      form_type_req: 'Form type required',
      appointment_type_req: 'Appointment type required',
      label_add_new_screening: 'Add New Screening',
      label_section_order_index: 'Order Index',
      error_invalid_heightft: 'Please enter valid details in Height field',
      error_invalid_heightIn: 'Please enter valid details in Inch field',
      section_name_req: 'Section name required',
      order_index_req: 'Order Index required',
      question_name_req: 'Question name required',
      option_req: 'Option required',
      option_min_req: 'Min value required',
      option_max_req: 'Max value required',
      min_option_create_limit: 'Minimum 2 options required',
      max_option_create_limit: 'Maximum 30 options allowed',
      min_peat_value: 'Minimum 0 value required',
      max_peat_value: 'Maximum 99 value allowed',
      error_bp: 'Please enter valid blood pressure value',
      label_client_id: 'ID',
      label_client_name: 'Client Name',
      label_address: 'Address',
      label_client_id_name: 'Client ID',
      label_new_client: 'Add New Client',
      label_client_mrn: 'MRN',
      label_client_organization: 'Organization',
      label_client_address_type: 'Address Type',
      label_new_dispatch_request: 'New Dispatch Request',
      label_create_dispatch_request: 'Create Dispatch Request',
      label_client_info: 'Client Info',
      label_create_new_request: 'Create New Request',
      label_client_registered: 'Is Client Registered?',
      label_who_called: 'Who Called',
      label_client_identification: 'Client Identification',
      label_alias: 'Alias',
      label_age: 'Age',
      label_date_of_birth: 'Date of Birth',
      label_gender: 'Gender',
      label_list_view: 'List View',
      label_activity: 'Activity',
      label_map_view: 'Map View',
      label_user_role: 'User Role',
      label_viewmap: 'Map',
      label_cancelRequest: 'Cancel',
      report_provider_name: 'Provider Name',
      report_provider_type: 'Reason for call',
      report_provider_first_name: 'First Name',
      report_provider_last_name: 'Last Name',
      report_provider_dob: 'DOB',
      report_dispatch_date: 'Dispatch Date',
      report_onscene_date: 'On Scene Date',
      report_provider_delaycode: 'Provider Delay Code',
      report_provider_created_date: 'Cleared Date',
      report_provider_cancellation_type: 'Cancellation Type',
      report_provider_cancellation_reason: 'Cancellation Reason',
      label_schedules: 'Schedules',
      report_dispatch_id: 'Dispatch Id',
      label_previous_caller: 'Previous referrals made to this caller',
      label_share_with_patient: 'Share With Client',
      label_contact_number_minlength: 'Please enter valid contact number',
      label_contact_number_maxlength: 'Please enter valid contact number',
      label_contact_number_required: 'Contact number is required',
      error_email_required: 'Please enter email',
      error_email_pattern: 'Please enter valid email',
      label_add_new_referral: 'Add New Referral',
      label_share_with_reporter: 'Share With Reporter',
      label_service_categories: 'Service Categories',
      label_select_referral_partner: 'Select Referral Partner',
      label_referral_given: 'Referral Given',
      label_no_referral_given: 'No Referrals available at the moment',
      label_no_records_found: 'No records available to show at this moment.',
      label_contact_form_exist: 'One or more contact forms already exist for this Client contact number.',
      report_contact_id: 'Contact Form Id',
      report_contact_form: 'Contact Form',
      report_service_category: 'Service Category',
      report_service_name: 'Service Name',
      report_crisis_phone_counselor_providing_resources: 'Crisis Phone Counselor Providing Resource',
      report_referral_presented: 'Referral Presented/Sent',
      report_organization_ame: 'Organization Name',
      report_date_and_time_of_resource_referred: 'Date and time of resource referred',
      report_mrn: 'Client Case No.',
      report_client_name: 'Client Name',
      report_reported_on: 'Reported On',
      report_primary_concern: 'Primary Concerns for Call',
      report_ccd_counsellor: 'CCD Counselor',
      report_contact_status: 'Contact Form Status',
      report_dispatch_status: 'Dispatch Status',
      report_client_contact: 'Client Contact',
      report_followup: 'Follow Up',
      label_ccd_counselor: 'CCD Counselor',
      label_referral_type: 'Referral Type',
      label_contact_form_status: 'Contact Form Status',
      label_date_range_note: '*Note: The date range should not exceed a week',
      label_date_range_monthly_note: '*Note: The date range should not exceed more than 2 months',
      report_contact_form_id: 'Contact Form ID',
      report_contact_method: 'Contact Method',
      report_initial_call: 'Date of Initial Call',
      report_duration_mins: 'Duration (mins)',
      report_contact_attempts: 'Contact Attempts Made So Far',
      report_crisis_phone: 'Crisis Phone Counselor Assigned to F/U',
      label_followup_status: 'Follow Up Status',
      label_assigned_to: 'Assigned To',
      label_contact_method: 'Contact Method',
      label_gun_on_scene: 'Gun On The Scene??(Ask regardless of SI/HI)',
      error_gun_on_scene_required: 'Gun On The Scene is required',
      error_si_plan_required: 'If SI within last 48hrs,is there a plan? (Document specifics) is required',
      label_si_plan: 'If SI within last 48hrs,is there a plan? (Document specifics)',
      error_aggression_required: 'Signs of aggression/violence in last 48hrs (Document specificas) is required',
      label_aggression: 'Signs of aggression/violence in last 48hrs (Document specificas)',
      label_safety_required: 'Willing to safety plan (SI) is required',
      label_safety_plan: 'Willing to safety plan (SI)?',
      error_safety_plan: 'Willing to safety plan (SI) is required',
      label_safety_violance: 'Willing to safety plan (violence)?',
      error_safety_violance: 'Willing to safety plan (violence) is required',
      label_violance_safety_plan: 'Suicide/Violence Safety Plan',
      error_violance_safety: 'Suicide/Violence Safety Plan is required',
      error_invalid_violance_safety: 'Invalid suicide/violence safety plan',
      label_child_protective_service: 'Child Protective Services',
      label_adult_protective_service: 'Adult Protective Services',
      label_dfps_operator_name_id: 'DFPS Operator Name/ID',
      label_dfps_report_rumber: 'DFPS Report Number',
      label_was_dfps_report_made: 'Was a DFPS (APS/CPS) Report made?',
      label_Did_cirt_mary_Unit_assist_on_this_call: 'Did CIRT/Mary Unit Assist On This Call?',
      label_hospital_name: 'Hospital Name',
      label_was_this_client_transported_to_the_hospital: 'Was this client transported to the hospital?',
      label_did_this_call_result_in_an_arrest: 'Did this call result in an arrest?',
      label_did_this_call_result_in_eod: 'Did This Call Result In EDO?',
      label_unassign_users: 'Unassign the user/s schedule from this team entirely',
      label_import_users_availability: 'Import User Availability',
      label_normal_filter: 'Normal',
      label_advanced_filter: 'Advanced',
      label_filter_all_categories: 'All Categories',
      label_filter_all_sub_categories: 'All Sub Categories',
      label_filter_all_items: 'All Items',
      label_filter_and_or: 'AND/OR',
      label_mobile_app_users: 'Mobile App Users',
      no_dispatch_request: 'No dispatch requests available at this moment.',
      label_manage_referral_partner: 'Manage Referral Partner',
      label_add_referral_partner: 'Add Referral Partner',
      label_update_referral_partner: 'Update Referral Partner',
      label_details_referral_partner: 'Details Referral Partner',
      label_ext: 'Ext',
      label_fxt: 'Fax',
      error_invalid_fax_number: 'Invalid Fax Number',
      label_website_link: 'Website Link',
      label_days_of_operation: 'Days Of Operation',
      label_hours_of_operation: 'Hours Of Operation',
      label_supported_languages: 'Supported Languages',
      label_charges: 'Charges',
      label_document_url: 'Document URL',
      label_document_url_mandatory: 'Document URL Mandatory',
      label_contact_email: 'Contact Email',
      label_contact_phone_number: 'Contact Phone Number',
      label_received_referrals: 'Received Referrals',
      error_service_category_required: 'Service category is required',
      report_service_sub_category: 'Service sub-category',
      error_service_sub_category_required: 'Service sub-category is required',
      label_created_by: 'Created By',
      label_assigned_by: 'Assigned To',
      label_follow_up_status: 'Status',
      label_followup_heading: 'All Follow-ups',
      followup_contact_id: 'Contact Form ID',
      followup_due_on: 'Due On',
      followup_contact_method: 'Contact Method',
      followup_created_by: 'Created By',
      followup_assigned_to: 'Assigned To',
      followup_status: 'Status',
      notes_title: 'Notes',
      label_open_shift: 'Open Shift',
      label_search_shift_planning: 'Search by Shift Schedule/Team Name',
      label_ends_on: 'Ends On',
      label_add_new_shift: 'Add New Shift',
      label_weekly_allocation: 'WEEKLY ALLOCATION',
      label_weekly_allocationp: 'Weekly Allocation',
      label_permanent_members: 'PERMANENT MEMBERS',
      label_permanent_members_lowercase: 'Permanent Members',
      label_shift_schedule: 'SHIFT SCHEDULE',
      label_shift_time: 'SHIFT TIME',
      label_ends_on_title: 'ENDS ON',
      label_team_title: 'TEAM',
      label_action_title: 'ACTION',
      label_no_shift: 'No shifts added',
      label_week_s: 'S',
      label_week_m: 'M',
      label_week_t: 'T',
      label_week_w: 'W',
      label_week_th: 'T',
      label_week_f: 'F',
      label_week_sa: 'S',
      label_start_time: 'Start Time',
      label_start_time_req: 'Start Time is required',
      label_end_time: 'End Time',
      label_serviceregion: 'Service Region',
      export_btn_label: 'Export',
      label_export_schedules: 'Export Schedules',
      error_date_required: 'Date is required',
      label_shift_manager_heading: 'Edit On Call Shift Manager Details',
      label_shift_manager_heading_add: 'Add On Call Shift Manager Details',
      label_shift_manager_details: 'On Call Manager Details',
      label_add_call_manager_details: 'Add on call shift details',
      label_load_more: 'Load More',
      label_unavailable: 'Unavailable',
      label_shift_planning_schedule: 'Enter Shift Schedule',
      label_recur_in_excel: 'Recur Every',
      label_end_by: 'End By',
      label_team_member_shift: 'Shift Details',
      label_team_members_name: 'Team Members',
      label_shift_upload: 'Shift Upload',
      label_cancel_leave: 'Cancel Leave',
      label_shift_applicable: 'Shift Applicable',
      label_deny: 'Deny',
      label_on_call_shift_heading: 'On Call Shift Manager Details',
      error_provider_required: 'Provider is required',
      label_powered_by: 'Powered By:',
      label_rainbow_llc: 'Rainbow Health LLC',
      label_version: 'Version',
      label_registered_email_msg: 'Please enter your registered email to proceed for password reset',
      label_forgot_username: 'Username',
      label_back_to: 'Back to',
      label_forgot_login: 'Login',
      label_manage_vehicle: 'Manage Vehicles',
      label_allocation_type: 'Allocation Type',
      label_assigned_members: 'Assigned Members',
      label_unassigned_text: 'Unassigned user/s from the below list will be removed permanently from the Team',
      label_no_team_member: 'No team member available',
      label_other_members: 'Other Members',
      label_assigned_users_text: 'Assigned user/s from the below list will be permanently added to the Team',
      label_provisionally_assigned: 'Provisionally Assigned',
      label_assigned_selected_date: '(*Members assigned for the selected dates will be updated in the system once you click on "Save")',
      label_team_date: 'DATE',
      label_team_member_name: 'MEMBER NAME',
      label_team_shift_time: 'SHIFT TIME',
      label_team_add_members: 'Add Members',
      label_team_assigned_list: 'Assigned user/s from the below list will be permanently added to the Team',
      label_import_option_required: 'Import option is required',
      label_recur_option_required: 'Recur every is required',
      label_end_by_date_required: 'End by date is required',
      label_example: 'Example:',
      label_import_text: 'If a Team Member is expected to work from Tuesday to Saturday every week from 17th of October 2023 until 31st of December 2023, then the Admin User must add date range with Start and End Date & Shift times for 7 days (October 17th - October 23rd )',
      label_import_start: 'Start',
      label_import_end: 'End',
      label_clients_heading: 'Clients',
      label_client_details_heading: 'Client Details',
      label_client_view_id: 'Client ID',
      label_client_view_name: 'Name',
      label_client_view_mobile: 'Mobile Number',
      label_client_view_alt_mobile: 'Alternate Mobile Number',
      label_client_view_address_type: 'Address Type',
      label_client_address: 'Address',
      label_client_case_no_applicable: 'Is MRN Applicable?',
      label_client_case_no_applicable_required: 'Client Case No. Applicable is required',
      label_client_case_no_required: 'MRN is required',
      label_client_child_adult: 'Child or Adult',
      label_client_language: 'Client Language',
      label_client_mobile_number: 'Mobile Number',
      label_client_alt_mobile_number: 'Alternate Mobile Number',
      label_dispatch_status: 'Dispatch Status',
      label_dispatch_service_region: 'Service Region',
      label_dispatch_reason_call: 'Reason for Call',
      label_dispatch_from_date: 'From Date',
      label_dispatch_to_date: 'To Date',
      label_dispatch_provider: 'Provider',
      label_dispatch_type_dropdown: 'Dispatch Type',
      label_dispatch_new_request: 'New Request',
      label_dispatch_pending_acceptance: 'Pending Acceptance',
      label_dispatch_accepted: 'Accepted',
      label_dispatch_enroute: 'En-Route',
      label_dispatch_cancelled: 'Cancelled',
      label_dispatch_onscene: 'On-Scene',
      label_dispatch_completed: 'Completed',
      label_dispatch_fully_completed: 'Dispatch Completed',
      label_dispatch_cancel: 'Cancel Request',
      label_dispatch_cancel_confirmation: 'Are you sure you want to cancel this request?',
      label_dispatch_cancel_reason: 'Cancellation Reason',
      label_dispatch_details_heading: 'Dispatch Request Details',
      label_dispatch_edit_heading: 'Edit Dispatch Request Details',
      label_dispatch_client_registered_required: 'Client registered is required',
      label_dispatch_search_client_by: 'Search the Client By',
      label_dispatch_minimum_character_required: 'Minimum 3 characters required',
      label_dispatch_maximum_character_required: 'You have exceeded the maximum 15 character limit',
      label_dispatch_id: 'Dispatch ID',
      label_dispatch_client_id: 'Client ID',
      label_dispatch_record_id: 'Record ID',
      label_dispatch_who_called_required: 'Who called is required',
      label_dispatch_client_identification_required: 'Client identification is required',
      label_dispatch_mrn_required: 'MRN is required',
      label_dispatch_minimum_two_character_required: 'Minimum 2 characters required',
      label_dispatch_first_name_required: 'First name is required',
      label_dispatch_maximum_twenty_character_required: 'You have exceeded the maximum 20 character limit',
      label_dispatch_last_name_required: 'Last name is required',
      label_dispatch_dateofbirth_required: 'Date of birth is required',
      label_dispatch_gender_required: 'Gender is required',
      label_dispatch_client_contact_number: 'Client Contact Number',
      label_dispatch_client_contact_number_required: 'Client contact number is required',
      label_dispatch_client_alt_contact_number: 'Client Alternate Contact Number',
      label_dispatch_reporter_name: 'Reporter Name',
      label_dispatch_who_called_name: 'Who Called - Name',
      label_dispatch_reporter_name_required: 'Reporter name is required',
      label_dispatch_maximum_thirty_character_required: 'You have exceeded the maximum 30 character limit',
      label_dispatch_relation: 'Relation with Client',
      label_dispatch_relation_client_required: 'Relation with client is required',
      label_dispatch_reporter_phone: 'Reporter Phone',
      label_dispatch_who_called_phone: 'Who Called - Phone',
      label_dispatch_reporter_phone_required: 'Reporter phone number is required',
      label_dispatch_reason_for_call: 'Reason for Call',
      label_dispatch_reason_for_call_required: 'Reason for Call is required',
      label_dispatch_dispatch_type: 'Dispatch Type',
      label_dispatch_dispatch_type_required: 'Dispatch Type is required',
      label_dispatch_service_region_dropdown: 'Service Region',
      label_dispatch_service_region_required: 'Service Region is required',
      label_dispatch_dispatch_address: 'Dispatch Address',
      label_dispatch_address_type: 'Address Type',
      label_dispatch_address_type_duplicated: 'Address Type is duplicated',
      label_dispatch_address_type_required: 'Address Type is required',
      label_dispatch_dispatch_location: 'Location',
      label_dispatch_dispatch_location_required: 'Location is required',
      label_dispatch_landmark: 'Landmark',
      label_dispatch_cross_streets: 'Cross Streets',
      label_dispatch_maximum_two_hundred_character_required: 'You have exceeded the maximum 200 character limit',
      label_dispatch_location_required: 'Location is required',
      label_dispatch_disposition_details: 'Disposition Details',
      label_dispatch_mobile_team_pd: 'Mobile Team contacted PD?',
      label_dispatch_macro_disposition: 'Macro Disposition',
      label_dispatch_mezzo_disposition: 'Mezzo Disposition',
      label_dispatch_micro_disposition: 'Micro Disposition',
      label_dispatch_mobile_team: 'Mobile Team Notes',
      label_dispatch_maximum_three_hundred_character_required: 'You have exceeded the maximum 300 character limit',
      label_dispatch_domestic_violence: 'Domestic Violence: Perpetrator Info',
      label_dispatch_perpetrator_name: 'Perpetrator Name',
      label_dispatch_perpetrator_sex: 'Perpetrator Sex',
      label_dispatch_perpetrator_race: 'Perpetrator Race',
      label_dispatch_perpetrator_age: 'Perpetrator Age',
      label_dispatch_perpetrator_height: 'Perpetrator Height',
      label_dispatch_perpetrator_weight: 'Perpetrator Weight',
      label_dispatch_perpetrator_hair: 'Perpetrator Hair',
      label_dispatch_perpetrator_current_location: `Perpetrator knows client's current location`,
      label_dispatch_perpetrator_identifying_mark: 'Perpetrator Identifying Marks / Clothing',
      label_dispatch_perpetrator_violence_info: 'Perpetrator Vehicle Info',
      label_dispatch_perpetrator_weapon: 'Perpetrator Weapons',
      label_dispatch_perpetrator_violence_history: 'Perpetrator Violence History',
      label_dispatch_perpetrator_last_seen: `Perpetrator's last Seen or present location`,
      label_dispatch_other_safety: 'Other Comments / Safety Concerns',
      label_dispatch_crisis_narrative: 'Crisis Narrative',
      label_dispatch_narrative: 'Narrative',
      label_dispatch_narrative_call_summary: 'Narrative/Call Summary',
      label_dispatch_fire_called: 'Fire/Police called?',
      label_dispatch_who_was_called: 'Who was called?',
      label_dispatch_who_was_called_required: 'Who was called is required',
      label_dispatch_what_number_dialed: 'What number was dialed?',
      label_dispatch_suicide_attempt: 'Suicide attempt in progress?',
      label_dispatch_alerts: 'Dispatch Alerts',
      label_dispatch_weapons: 'Weapons',
      label_dispatch_pets: 'Pets',
      label_dispatch_current_cot: 'Current COT',
      label_dispatch_substances: 'Substances',
      label_dispatch_dto: 'DTO',
      label_dispatch_dto_history: 'History of DTO',
      label_dispatch_dts_history: 'History of DTS',
      label_dispatch_contagious: 'Contagious',
      label_dispatch_medical: 'Medical',
      label_dispatch_medication: 'Medication',
      label_dispatch_comments: 'Comments',
      label_dispatch_notes: 'Dispatch Notes',
      label_dispatch_created_by: 'Created By:',
      label_dispatch_dispatch_details: 'Dispatch Details',
      label_dispatch_dispatched_provider: 'Dispatched Provider',
      label_dispatch_dispatched_team: 'Dispatched Team',
      label_dispatch_vehicle_number: 'Vehicle Number',
      label_dispatch_delays: 'Delays',
      label_dispatch_delays_code: 'Delay Code',
      label_dispatch_reason: 'Reason',
      label_dispatch_safety_check: 'Safety Check',
      label_dispatch_activity_log: 'Dispatch Activity Log',
      label_dispatch_cancelled_log: ' Dispatch Cancelled Log',
      label_dispatch_cancelled_on: 'Cancelled On',
      label_dispatch_cancelled_reason: 'Reason',
      label_dispatch_add_delay: 'Add Delay',
      label_dispatch_delay_code: 'Delay Code',
      label_dispatch_delay_reason: 'Reason',
      label_dispatch_add_notes: 'Add Dispatch Notes',
      label_invalid_dispatch_add_notes: ' Invalid add Dispatch Notes',
      label_map_view_hide_filter: 'Hide Filter',
      label_map_dispatch_status: 'Dispatch Status',
      label_map_new_request: 'New Request',
      label_map_pending_acceptance: 'Pending Acceptance',
      label_map_acknowledged: 'Acknowledged',
      label_map_enroute: 'Enroute',
      label_map_cancelled: 'Cancelled',
      label_map_on_scene: 'On Scene',
      label_map_dispatch_completed: 'Dispatch Completed',
      label_map_team_status: 'Team Status',
      label_map_available: 'Available',
      label_map_unavailable: 'Unavailable',
      label_map_reserved: 'Reserved',
      label_map_multiple_dispatches: 'Multiple Dispatches',
      label_map_team_availability: 'Team Availability',
      label_map_provider: 'Provider:',
      label_map_na: 'NA',
      label_map_unavailable_until_text: 'Unavailable Until:',
      label_map_updated_by: 'Updated By:',
      label_map_comments: 'Comments:',
      label_map_client: 'Client:',
      label_map_primary_call: 'Primary Concerns for Call:',
      label_map_primary_call_l: 'Primary Concerns for Call',
      label_map_reserved_until: 'Reserved Until:',
      label_map_reserved_by: 'Reserved By:',
      label_map_dispatch_request: 'Dispatch Request',
      label_map_view_more: 'View More',
      label_map_team: 'Team:',
      label_map_add_note: 'Add Note',
      label_map_cancel_request: 'Cancel Request',
      label_map_are_sure_cancel: 'Are you sure you want to cancel this request?',
      label_map_cancellation_reason: 'Cancellation Reason',
      label_invalid_map_cancellation_reason: 'Invalid cancellation Reason',
      label_map_notes: 'Notes',
      label_map_add_notes: 'Add Notes',
      label_invalid_map_add_notes: 'Invalid add Notes',
      label_map_created_by: 'Created By:',
      label_map_mark_unavailable: 'Mark as Unavailable',
      label_map_unavailable_until: 'This team will be unavailable till next',
      label_map_value_is_required: 'Value is required.',
      label_map_mins: 'Mins',
      label_map_comments_value: 'Comments',
      label_invalid_map_comments_value: 'Invalid comments',
      label_map_reserve_dispatch_request: 'Reserve Dispatch Request',
      label_map_reserve_team: 'Reserve the team for next',
      label_map_add_delay: 'Add Delay',
      label_invalid_map_add_delay: 'Invalid Delay Note',
      label_map_delay_code: 'Delay Code',
      label_map_delay_reason: 'Reason',
      label_schedule_date: 'Date',
      label_schedule_shift_time_note: '* All the shift timings shown below are in CST timezone',
      label_vehicle_provider: 'Provider',
      label_vehicle_text: 'Vehicle',
      label_mobile_text: 'Mobile',
      label_vehicle_dispatch_completed: 'Dispatch Completed',
      label_vehicle_cancelled: 'Cancelled',
      label_vehicle_dispatch_request: 'Dispatch Request',
      label_vehicle_search_by_name: 'Search by vehicle name to view the vehicle history',
      label_team_history_tabs: 'Team History',
      label_team_provider_name: 'Provider',
      label_team_from_date: 'From Date',
      label_team_to_date: 'To Date',
      label_reports_title: 'Reports',
      label_send_report_via_email: 'Send report via email ',
      label_manage_roles_title: 'Manage Roles',
      label_role_column_name: 'Name',
      label_role_grant_access: 'Grant Access',
      label_role_details_title: 'Role Details',
      label_users_manage_title: 'Manage Users',
      label_about_company_title: 'About Company',
      label_faqs_title: 'FAQs',
      label_add_new_faq: 'Add New',
      label_terms_condition_title: 'Terms And Conditions',
      label_segments_title: 'Segments',
      label_notifications_title: 'Notifications',
      label_message_length_error: 'Message length',
      label_invalid_message_error: 'Invalid Message',
      label_rainbow_health_title: 'Harris Center',
      label_manage_shift_title: 'Manage Shifts',
      label_week_title: 'Week',
      label_day_title: 'Day',
      label_team_open_Shift: 'Open Shifts',
      label_team_shift_availability: 'Member Shift Availability',
      label_team_today_title: 'Today',
      label_shift_no_records_available: 'No records available',
      label_shift_more: 'more',
      label_shift_team_text: 'Team',
      label_shift_ends_on: 'Ends on:',
      label_recur_every_required: 'Recur every is required',
      label_no_text: 'No',
      label_open_text: 'Open',
      label_shift_text: 'Shifts',
      label_published_text: 'Published',
      label_publish_open_shift: 'Publish Open Shift',
      label_open_shift_for: 'Open Shift For',
      label_open_shift_required: 'Open Shift For is required',
      label_open_shift_team_required: 'Team is required',
      label_open_shift_team: 'Team',
      label_open_shift_applications: 'Applications',
      label_open_shift_applications_required: 'Applications is required',
      label_open_shift_shift_date: 'Shift Date',
      label_open_shift_shift_date_required: 'Shift Date is required',
      label_open_shift_shift_team_members: 'Team Members',
      label_open_shift_shift_team_members_required: 'Team Members is required',
      label_open_shift_shift_time: 'Shift Time',
      label_open_shift_shift_time_required: 'Shift Time is required',
      label_open_shift_shift_end_time_required: 'End Time is required',
      label_open_shift_shift_notes: 'Notes',
      label_team_assigned: 'Team Assigned',
      label_manage_referral_partners: 'Manage Referral Partners',
      report_organization_ame_required: 'Organization Name is required',
      label_service_category: 'service category',
      label_map_primary_call_required: 'Primary Concerns for Call is required',
      label_performed_by: 'Performed By',
      label_invalid_age: 'Invalid Age',
      label_other_specify_below: 'Other, specify below',
      label_npr_referral_disposition: 'MCOT referral disposition',
      label_npr_transport_this_client: 'Where did MCOT transport this client?',
      send_email_btn_label: 'Send report via email',
      cf_label_edit: 'Edit',
      cf_label_dispatch: 'Dispatch',
      cf_label_download: 'Download',
      cf_label_shareViaEmail: 'Share via Email',
      cf_label_gotoDispatch: 'Go to dispatch details',
      cf_accordion_contact_details: 'Contact Details',
      cf_label_info_detail: 'Unless otherwise specified, this information is directly related to a call to Houston 911 or the Houston Police Department Non-Emergency line.',
      cf_label_contact_form_id: 'Contact Form ID',
      cf_label_created_by: 'Created by',
      cf_label_date: 'Date',
      cf_validation_date_error: 'Date is required',
      cf_label_start_time: 'Start Time',
      cf_validation_start_time_error: 'Start Time is required',
      cf_label_end_time: 'End Time',
      cf_validation_end_time_error: 'End Time is required',
      cf_validation_who_called_error: 'Who called is required',
      cf_label_client_contact: 'Client Contact Number',
      cf_label_click_here: 'Click Here',
      cf_label_is_mrn_applicable: 'Is MRN Applicable?',
      cf_validation_min_mrn: 'Minimum 2 characters required',
      cf_validation_max_mrn: 'You have exceeded the maximum 15 character limit',
      cf_validation_first_name_error: 'First name is required',
      cf_validation_ssn_error: 'SSN is required',
      cf_validation_mrn_error: 'MRN is required',
      cf_validation_min_first_name: 'Minimum 1 characters required',
      cf_validation_max_first_name: 'You have exceeded the maximum 20 character limit',
      cf_validation_req_last_name: 'Last name is required',
      cf_validation_min_last_name: 'Minimum 1 characters required',
      cf_validation_max_last_name: 'You have exceeded the maximum 20 character limit',
      cf_validation_req_gender: 'Gender is required',
      cf_validation_req_date_of_birth: 'Date of birth is required',
      cf_validation_min_age: 'You have exceeded the maximum 3 digit limit',
      cf_validation_pat_age: 'Invalid Age',
      cf_label_child_adult: 'Child or Adult',
      cf_label_client_alt_number: 'Client Alternate Contact Number',
      cf_label_client_language: 'Client Language',
      cf_label_dispatch_address: 'Dispatch Address',
      cf_label_address: 'Address',
      cf_label_address_type: 'Address Type',
      cf_label_address_type_duplicate: 'Address Type is duplicated',
      cf_validation_req_address_type: 'Address Type is required',
      cf_label_location: 'Location',
      cf_validation_req_location: 'Location is required',
      cf_label_landmark: 'Landmark',
      cf_validation_min_landmark: 'Minimum 3 characters required',
      cf_validation_max_landmark: 'You have exceeded the maximum 50 character limit',
      cf_label_reporter_details: 'Reporter Details',
      cf_label_reporter_name: 'Reporter Name',
      cf_validation_req_reporter_name: 'Reporter Name is required',
      cf_validation_pat_reporter_name: 'Invalid reporter name',
      cf_label_relation_with_client: 'Relation with Client',
      cf_validation_req_relation_with_client: 'Relation with Client is required',
      cf_label_reporter_contact_number: 'Reporter Contact Number',
      cf_validation_req_reporter_contact: 'Reporter Contact Number is required',
      cf_label_reporter_language: 'Reporter Language',
      cf_validation_req_reporter_language: 'Reporter Language is required',
      cf_label_guardian_name: 'Legal Guardian Name, if different from Caller',
      cf_validation_pat_guardian_name: 'Invalid Legal Guardian Name, if different from Caller',
      cf_label_guardian_phone: 'Legal Guardian Phone number',
      cf_label_third_party_address: 'Third party address',
      cf_label_pat_third_party_address: 'Invalid Third party address',
      cf_label_client_info: 'Additional Client Information',
      cf_label_current_mh_provider: 'Current MH Provider',
      cf_label_sai: 'SAI',
      cf_validation_req_sai: 'SAI is required',
      cf_validation_pat_sai: 'Invalid SAI',
      cf_label_social_num: 'Social Security Num',
      cf_validation_pat_social_num: 'Invalid Social Security Number',
      cf_label_insurance_cover: 'Insurance Coverage',
      cf_label_served_in_military: 'Previously served in the military',
      cf_validation_req_served_in_military: 'Previously served in the military is required',
      cf_label_language_line_info: 'Language Line Info.',
      cf_label_language_line_used: 'Language Line used (Agent ID)',
      cf_validation_pat_language_line_used: 'Invalid language line used (Agent ID)',
      cf_label_language_line: 'Language Used on Language Line',
      cf_validation_pat_language_line: 'Invalid language used on language line',
      cf_label_crisis_details: 'Crisis Details',
      cf_label_instructions: `Instructions: Complete this call report form using the prompts found on the CCD Calenders's 'Risk Assessments' tab.`,
      cf_label_concerns: 'Concerns',
      cf_label_primary_concern_call: 'Primary Concerns For Call',
      cf_validation_req_primary_concern_call: 'Primary Concerns For Call is required',
      cf_label_specify_below: 'Other, specify below',
      cf_label_other_primary_concern: 'You have exceeded the maximum 300 character limit',
      cf_validation_pat_other_primary_concern: 'Invalid other, specify below',
      cf_label_crisis_narrative: 'Crisis Narrative',
      cf_label_mcot: 'MCOT',
      cf_validation_req_mcot: 'MCOT is required',
      cf_label_mcot_rr_dispatched: 'if MCOT-RR is Not dispatched, select the primary reason only',
      cf_validation_req_mcot_rr_dispatched: 'if MCOT-RR is Not dispatched, select the primary reason only is required',
      cf_validation_max_other_primary_mcot: 'You have exceeded the maximum 300 character limit',
      cf_label_outcome: 'Outcome',
      cf_label_timeframe: 'Timeframe at end of call',
      cf_validation_req_timeframe: 'Timeframe at end of call is required',
      cf_label_crisis_deescalated: 'Crisis de-escalated',
      cf_validation_req_crisis_deescalated: 'Crisis de-escalated is required',
      cf_label_client_satisfaction_quote: 'Client satisfaction quote',
      cf_validation_pat_invalid_client_satisfaction: 'Invalid client satisfaction quote',
      cf_label_current_client: 'if current client, service provider was sent 911 call notification',
      cf_label_cad_outcomes: 'CAD Outcomes',
      cf_label_referral_source: 'Referral Source',
      cf_validation_req_referral_source_required: 'Referral Source is required',
      cf_label_call_slip_time: 'Call Slip Time',
      cf_validation_req_call_slip_time_required: 'Call Slip Time is required',
      cf_validation_pat_invalid_call_slip_time: 'Invalid call slip time',
      cf_label_call_code: 'Call Code',
      cf_validation_req_call_code_required: 'Call Code is required',
      cf_validation_pat_call_code_invalid: 'Invalid call code',
      cf_label_cad_call_slip: 'CAD Call Slip #',
      cf_validation_req_cad_call_slip_required: 'CAD Call Slip is required',
      cf_validation_pat_cad_call_slip_invalid: 'Invalid CAD call slip',
      cf_label_disposition_code: 'Disposition Code',
      cf_validation_req_disposition_code_required: 'Disposition Code is required',
      cf_validation_pat_disposition_code_invalid: 'Invalid disposition code',
      cf_label_police_dispatched: 'Police Dispatched',
      cf_validation_req_police_dispatched_required: 'Police Dispatched is required',
      cf_label_officer_arrival_time: 'Officer Arrival Time',
      cf_validation_req_officer_arrival_time_required: 'Officer Arrival Time is required',
      cf_validation_pat_officer_arrival_time_invalid: 'Invalid officer arrival time',
      cf_label_officer_clear_time: 'Officer Clear Time',
      cf_validation_req_officer_clear_time_required: 'Officer Clear Time is required',
      cf_validation_pat_officer_clear_time_invalid: 'Invalid officer clear time',
      cf_label_police_diverted: 'Police Diverted',
      cf_validation_req_police_diverted_required: 'Police Diverted is required',
      cf_label_hfd_incident: 'HFD Incident',
      cf_label_hfd_incident_symbol: 'HFD Incident #',
      cf_validation_req_hfd_incident_required: 'HFD Incident is required',
      cf_validation_pat_hfd_incident_invalid: 'Invalid HFD incident',
      cf_label_hfd_dispatched: 'HFD Dispatched',
      cf_validation_req_hfd_dispatched_required: 'HFD Dispatched is required',
      cf_label_hfd_diverted: 'HFD Diverted',
      cf_validation_req_hfd_diverted_required: 'HFD Diverted is required',
      cf_label_complete_covid: 'Complete COVID-19 Screening To Dispatch MCOT',
      cf_label_covid_symptoms: 'Are you or anyone in your household experiencing any COVID symptoms?',
      cf_validation_req_covid_symptoms_required: 'Are you or anyone in your household experiencing any COVID symptoms? is required',
      cf_label_contact_with_covid: 'Have you or anyone in your household had close contact with anyone with COVID-19?',
      cf_label_close_contact_covid: 'Have you or anyone in your household had close contact with anyone with COVID-19? is required',
      cf_label_covid_notes: 'COVID Screening Notes',
      cf_validation_pat_covid_notes_invalid: 'Invalid COVID screening notes',
      cf_label_rate_distress_level: 'Rate your distress level on a scale from 1 - 10 when first calling 911?',
      cf_validation_req_distress_level_required: 'Rate your distress level on a scale from 1 - 10 when first calling 911? is required',
      cf_label_distress_level_scale: 'Rate your distress level on a scale from 1 - 10 after speaking with CCD?',
      cf_validation_req_distress_level_scale_required: 'Rate your distress level on a scale from 1 - 10 after speaking with CCD? is required',
      cf_label_content_title: 'Content',
      cf_label_client_additional_desc: 'Client Additional Description',
      cf_validation_req_client_additional_desc_required: 'Client Additional Description is required',
      cf_label_race: 'Race',
      cf_label_ethnicity: 'Ethnicity',
      cf_label_hair_color: 'Hair Color',
      cf_label_approx_weight: 'Approximate Weight',
      cf_validation_max_approx_weight: 'Please enter valid details in Approximate Weight field',
      cf_label_clothing: 'Clothing',
      cf_validation_pat_clothing_invalid: 'Invalid clothing',
      cf_label_area_apt_name: 'Area / Apartment Name',
      cf_validation_pat_area_apt_name_invalid: 'Invalid area / apartment name',
      cf_label_gate_code: 'Gate Code',
      cf_validation_max_gate_code: 'You have exceeded the maximum 10 character limit',
      cf_validation_pat_gate_code_invalid: 'Invalid gate code',
      cf_label_student: 'Student',
      cf_label_employment: 'Employment',
      cf_label_household_income: 'Household Income',
      cf_label_education: 'Education',
      cf_label_special_needs: 'Special needs (such as transportation,physical limitations,scheduling needs)',
      cf_validation_pat_special_needs_invalid: 'Invalid Special needs (such as transportation,physical limitations,scheduling needs)',
      cf_label_referral_presented: 'Referral Presented',
      cf_label_referral_sent: 'Referral Sent',
      cf_label_referral_client_contact: 'Client Contact:',
      cf_label_client_email: 'Client Email:',
      cf_label_referral_reporter_contact: 'Reporter Contact:',
      cf_label_scheduled_followups: 'Scheduled Follow-ups',
      cf_label_followup_calls_timeframe: 'Reminder: Complete follow up calls based on timeframe:',
      cf_label_followup_emergent: 'Emergent - complete a follow up call to client/caller within 30mins of the initial assessment.',
      cf_label_followup_urgent: 'Urgent - complete a follow up call to client/caller within 2 - 4hrs of the initial assessment.',
      cf_label_dispatch_req: 'Dispatch Request',
      cf_label_sent_via_email: 'Sent Via Email',
      cf_label_by: 'by',
      cf_label_cancel: 'Cancel',
      cf_label_subject: 'Subject',
      cf_validation_req_subject_required: 'Subject is required',
      cf_validation_req_share_email_required: 'Email is required',
      cf_label_contact_form_details: 'Contact Form Details',
      cf_label_popup_contact_form_id: 'Contact Form Id',
      cf_label_contact_client_name: 'Client Name',
      cf_label_dob: 'Date of Birth',
      cf_label_zip_code: 'Zip Code',
      cf_label_days_of_operation: 'Days Of Operation:',
      cf_label_age_criteria: 'Age Criteria:',
      cf_label_insurance: 'Insurance:',
      cf_label_point_of_contact: 'Point of contact:',
      cf_label_popup_referral_presented: 'Referral Presented',
      cf_label_send_referral: 'Send Referral',
      cf_label_email: 'email',
      cf_label_cancel_btn: 'Cancel',
      cf_label_add_follow_up: 'Add New Follow Up',
      cf_label_edit_follow_up: 'Edit Follow Up',
      cf_label_view_follow_up: 'View Follow Up',
      cf_label_contact_method: 'Contact method',
      cf_validation_req_contact_method_required: 'Contact method is required',
      cf_label_assigned_to: 'Assigned To',
      cf_validation_req_assigned_to_required: 'Assigned To is required',
      cf_label_edit_btn: 'Edit',
      cf_label_followup_status: 'Status',
      cf_validation_req_followup_status_required: 'Status is required',
      cf_label_due_on: 'Due On',
      cf_validation_req_followup_date_required: 'Date is required',
      cf_label_followup_at: 'At',
      cf_validation_req_followup_at_required: 'At is required',
      cf_label_followup_phone: 'Phone',
      cf_validation_req_followup_phone_required: 'Phone is required',
      cf_label_followup_name_ask: 'Name to ask for',
      cf_validation_req_followup_name_ask_required: 'Name to ask for is required',
      cf_label_followup_with: 'Follow-up is with',
      cf_validation_req_followup_with_required: 'Follow-up is with is required',
      cf_label_followup_try_to_contact: 'Try to contact',
      cf_validation_req_followup_try_to_contact_required: 'Try to contact is required',
      cf_label_followup_contact_attempts: 'Contact attempts made so far',
      cf_validation_req_followup_contact_attempts_required: 'Contact attempts made so far is required',
      cf_label_followup_minutes_spent: 'Minutes spent on this activity',
      cf_validation_req_followup_minutes_spent_required: 'Minutes spent on this activity is required',
      cf_validation_max_followup_minutes_spent: 'You have exceeded the maximum 400 limit',
      cf_label_followup_subject: 'Subject',
      cf_validation_req_followup_subject_required: 'Subject is required',
      cf_label_followup_instruction_notes: 'Instructions and Notes',
      cf_label_add_new_followup_btn: 'Add New Follow Up',
      cf_label_clear_followup_btn: 'Clear Follow Up',
      cf_label_followup_cancel_btn: 'Cancel',
      cf_label_followup_save_btn: 'Save',
      label_shift_start_time: 'Shift Start Time',
      label_shift_end_time: 'Shift End Time',
      label_bulk_warning: 'Note: When user select ends on as "Never", the shift will be assigned to the user for {{maxDays}} days',
      label_patient_demo: 'Patient Demographics',
      label_ssn: 'Social Security Number (SSN)',
      label_Mrn: 'Medical Record Number (MRN)',
      label_Mrn_No: 'MRN Number',
      label_ssn_no: 'SSN Number',
      label_look_up: 'Look Up Client Data',
      label_search_criteria: 'To look up patient record in the Epic EHR, One of the following search combinations is necessary.',
      label_criteria_mrn: 'Medical Record Number (Patient MRN).',
      label_criteria_name_birth_date: 'FirstName, LastName and Date Of Birth.',
      label_criteria_name_gender_email: 'FirstName, LastName, Gender and Phone Number/Email.',
      label_search_result: 'Search Results',
      err_provider_required: 'Provider is required',
      add_team_label_shift_ends_on: 'Shift Ends On',
      add_team_label_start_date: 'Shift Start Date',
      add_team_label_end_date: 'Shift End Date',
      add_team_label_shift_start_time: 'Shift Start Time',
      add_team_label__shift_end_time: 'Shift End Time',
      add_team_msg_add_shift_plan: 'Would you like to add the Shift Plan as well?',
      label_shift_plan: 'Would you like to add the Shift Plan as well?',
      err_end_time: 'End Time is Required',
      label_update_shift_plan: 'Would you like to update the Shift Plan as well?',
      label_update_contact_form: 'Update Contact Form',
    });
  }
}
