import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactFormService } from 'app/modules/contact-form/services/contact-form.service';
import { LookupService } from 'app/modules/contact-form/services/lookup.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MiGrid2Component } from 'app/modules/shared/components/mi-grid-2/mi-grid-2.component';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { StorageService } from 'app/modules/shared/services/storage.service';
import * as moment from 'moment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng';

@Component({
  selector: 'app-create-component',
  templateUrl: './create-component.component.html',
  styleUrls: ['./create-component.component.scss']
})
export class CreateComponentComponent implements OnInit {
  @ViewChild(MiGrid2Component) gridRef: MiGrid2Component;
  columns: any = [];
  buttons: any[] = [];
  myInputDisabled: boolean = false;
  storedFirstName;
  storedLastName;
  dialogFirstName;
  showResult = false;
  dateFormat = new Date();
  url = '';
  genders: any[] = [
    { label: 'Male', value: '370' },
    { label: 'Female', value: '371' }
  ];
  lookupData: any[] = [];
  location: any;
  ngForm: FormGroup;
  genderList: any[] = [];
  countryCodeList = AppSettings.COUNTRY_CODE;
  minDate: Date;
  maxDate: Date;
  defaultDate: Date;
  expiryDateRange: string;
  issueDateRange: string;
  isEditContact: any;
  contactFormData;
  showUpdateButton = false;

  constructor(
    private router: Router,
    private contactFormService: ContactFormService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private storageService: StorageService,
    private fb: FormBuilder,
    private commonService: CommonBindingDataService,
    private route: ActivatedRoute,
    private lookupService: LookupService
  ) { 

  }

  ngOnInit(): void {

    this.ngForm = this.fb.group({
      radiomrn: [''],
      radioValue: ['SSN'],
      radioFirstName: [''],
      ssn: ['', Validators.required],
      mrn: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(20),
      Validators.pattern(AppSettings.ALPHA_NUMERIC_PATTERN_NAME_MIN)]],
      lastName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(20),
      Validators.pattern(AppSettings.ALPHA_NUMERIC_PATTERN_NAME_MIN)
      ]],
      email: ['', Validators.pattern(AppSettings.EMAIL_PATTERN)],
      gender: ['', Validators.required],
      dateOfBirth: [''],
      addressLineOne: [''],
      zipCode: ['', [Validators.pattern(AppSettings.ALPHA_NUMERIC), Validators.minLength(5), Validators.maxLength(6)]],
      city: [''],
      state: [''],
      country: [''],
      contactNumber: ['', [Validators.maxLength(12)]],
    });
    window['from'] = this.ngForm;
    this.setDateFormat();
    this.onSelectionChange();
    this.setTagData();
    this.storageService.logoutPopup.subscribe(ele => {
      if (ele) {
        this.ref.close();
      }
    });
    this.route.queryParams.subscribe(params => {
      const isAddContactForm = window.location.href.includes('add-contact-form');
      this.isEditContact = Boolean((params?.id && params?.edit) || isAddContactForm);
      this.showUpdateButton = Boolean(params?.id && params?.edit)
      this.getData();      
    });
  }

  getData() {
    this.contactFormData = this.lookupService.getData(); 
    this.getContactFormDetailsData();
  }

  getContactFormDetailsData() {
      if(this.contactFormData.mrn){
        this.ngForm.controls.radioValue.setValue('HCMRN');
        this.onSelectionChange();
        this.ngForm.controls.mrn.setValue(this.contactFormData.mrn);
      }
      else if(!this.contactFormData['mrn'] && Object.keys(this.contactFormData).length>0){    
        this.ngForm.controls.radioValue.setValue('');
        this.onSelectionChange();        
        this.ngForm.controls.firstName.setValue(this.contactFormData?.firstName);
        this.ngForm.controls.lastName.setValue(this.contactFormData?.lastName);
        this.ngForm.controls.gender.setValue((this.contactFormData?.gender)?.toString());
        const dateOfBirth = this.contactFormData['dateOfBirth'];
        if (dateOfBirth) {
          const dob = this.commonService.toTimezoneDateStringFormat(dateOfBirth, 'Europe/London');
          this.ngForm.controls['dateOfBirth'].setValue(dob);
        } 
        this.ngForm.controls.contactNumber.setValue(this.addPhoneNumberFormat(this.contactFormData?.phoneNum));
        this.ngForm.controls.email.setValue(this.contactFormData?.email);         
      }    
  }

  /*getContactFormDetailsData(contactID) {
    this.contactFormService.getContactFormDetails(contactID).subscribe(results => {
      if(results.contactForm.user.mrnApplicable){
        this.ngForm.controls.radioValue.setValue('HCMRN');
        this.onSelectionChange();
        this.ngForm.controls.mrn.setValue(results.contactForm.user.memberId);
      }
      if(!results.contactForm.user.mrnApplicable){    
        this.ngForm.controls.radioValue.setValue('');
        this.onSelectionChange();        
        this.ngForm.controls.firstName.setValue(results.contactForm.user?.firstName);
        this.ngForm.controls.lastName.setValue(results.contactForm.user?.lastName);
        this.ngForm.controls.gender.setValue((results.contactForm.user.gender).toString());
        const dob = this.commonService.toTimezoneDateStringFormat(results.contactForm.user.dateOfBirth, 'Europe/London');  
        this.ngForm.controls.dateOfBirth.setValue(dob);
        this.ngForm.controls.contactNumber.setValue(this.addPhoneNumberFormat(results.contactForm?.phoneNum));
        this.ngForm.controls.email.setValue(results.contactForm.user?.email);
        this.ngForm.controls.addressLineOne.setValue(results.contactForm.user.userDetails?.addressLine1);
        this.ngForm.controls.city.setValue(results.contactForm.user.userDetails?.city);
        this.ngForm.controls.state.setValue(results.user.userDetails?.state);
        this.ngForm.controls.country.setValue(results.user.userDetails?.country);
        this.ngForm.controls.zipCode.setValue(results.user.userDetails?.zipcode);       
      }
    });
  }*/
 
  addPhoneNumberFormat(value) {
    if (value) {
      value = value.replace(/-/g, '');;
      return value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6);
    }
  }

  onMobileChange(event) {
    const formControl = this.ngForm.controls;
    let newValue = this.phoneNumberFormat(formControl.contactNumber.value);
    if (event.code === 'Backspace' && newValue.length <= 1) {
      newValue = '';
    }
    let newVal = '';
    if (newValue) {
      newVal = newValue.replace(/\D/g, '');
    }
    formControl.contactNumber.setValue(newVal);
    if (formControl.contactNumber.value.length >= 1 && formControl.contactNumber.value.length < 10) {
      formControl.contactNumber.setErrors({ maxlength: true });
    } else {
      formControl.contactNumber.setErrors(null);
    }
  }

  phoneNumberFormat(data) {
    if (data) {
      let phoneNumber = data.replace(/\s+/g, '');
      return phoneNumber.split('-').join('');
    }
  }

  get isFormValid(): boolean {
    return (
      this.ngForm.controls.ssn.valid || (this.ngForm.controls.firstName.valid &&
        this.ngForm.controls.lastName.valid) || this.ngForm.controls.mrn.valid
    );
  }

  setTagData() {
    const tagsData = this.storageService.getItem(AppSettings.TAG_DATA);
    if (tagsData === null) {
      const localTagData = localStorage.getItem('localTagdata');
      this.setDropdownValues(JSON.parse(localTagData));
    } else {
      this.setDropdownValues(tagsData);
    }
  }

  applyTrimming(controlNames: string[]) {
    controlNames.forEach(controlName => {
      const control = this.ngForm.get(controlName);
      control?.valueChanges.subscribe(value => {
        if (value && value !== value.trim()) {
          control.setValue(value.trim(), { emitEvent: false });
        }
      });
    });
  }

  onOptionChange() {
    if (this.ngForm.controls.radiossn.value === 'SSN') {
      this.ngForm.get('mrn')?.disable();
      this.ngForm.get('ssn')?.enable();
    }
    if (this.ngForm.controls.radiomrn.value === 'HCMRN') {
      this.ngForm.get('ssn')?.disable();
      this.ngForm.get('mrn')?.enable();
    }
  }

  setDateFormat() {
    this.dateFormat = this.commonService.getDateFormat();
    const prevMonth = (new Date().getMonth() === 0) ? 11 : new Date().getMonth() - 1;
    const prevYear = (prevMonth === 11) ? new Date().getFullYear() - 1 : new Date().getFullYear();
    const nextMonth = ((new Date().getMonth()) === 11) ? 0 : (new Date().getMonth()) + 1;
    const nextYear = (nextMonth === 0) ? (new Date().getFullYear()) + 1 : (new Date().getFullYear());
    this.minDate = new Date();
    this.maxDate = new Date(nextYear - 1, 12, 0);
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);
    this.expiryDateRange = prevYear + ':' + (nextYear + 20);
    this.issueDateRange = (prevYear - 150) + ':' + (nextYear - 1);
    this.defaultDate = new Date();
    this.defaultDate.setFullYear(nextYear - 1);
    this.ngForm.controls.dateOfBirth.setValue(new Date());
  }

  private buildSearchModel(searchOnEhr: boolean): any {
    let identify: string;

    if (this.ngForm.controls.radioValue.value === 'SSN') {
      identify = `${this.ngForm.controls.radioValue.value}|${this.ngForm.controls.ssn.value}`;
    }
    else if (this.ngForm.controls.radioValue.value === 'HCMRN') {
      identify = `${this.ngForm.controls.radioValue.value}|${this.ngForm.controls.mrn.value}`;
    }
    else {
      identify = '';
    }

    let cleanPhoneNumber = '';
    if(this.ngForm.controls?.contactNumber.value){
      cleanPhoneNumber = this.ngForm.controls?.contactNumber.value.replace(/-/g, '');
    }    

    return {
      firstName: this.ngForm.controls.firstName.value,
      lastName: this.ngForm.controls.lastName.value,
      gender: this.ngForm.controls.gender.value,
      email: this.ngForm.controls.email.value,
      dateOfBirthStr: this.formatDate(this.ngForm.controls.dateOfBirth.value),
      addressLine1: this.ngForm.controls.addressLineOne.value,
      city: this.ngForm.controls.city.value,
      state: this.ngForm.controls.state.value,
      country: this.ngForm.controls.country.value,
      zipcode: this.ngForm.controls.zipCode.value,
      phoneNum: cleanPhoneNumber,
      identifier: identify,
      searchOnEhr
    };
  }

  private formatDate(date: Date | string): string {
    const dt = typeof date === 'string' ? new Date(date) : date;
    if (isNaN(dt.getTime())) return '';

    const month = ('0' + (dt.getMonth() + 1)).slice(-2);
    const day = ('0' + dt.getDate()).slice(-2);
    const year = dt.getFullYear();

    return `${month}/${day}/${year}`;
  }

  searchLookupDms(): void {
    const searchModel = this.buildSearchModel(false);
    this.contactFormService.searchLookupDmsData(searchModel).subscribe(results => {
      this.showResult = true;
      this.lookupData = results.data;
    },
    );
  }

  searchLookupEhr(): void {
    const searchModel = this.buildSearchModel(true);
    this.contactFormService.searchLookupDmsData(searchModel).subscribe(results => {
      this.showResult = true;
      this.lookupData = results.data;
    });
  }

  gotoAddContactForm(event: any) {
    const dms = event;
    this.contactFormService.isContactFormPage = true;
    this.contactFormService.getContactFormId().subscribe(results => {
      this.storageService.setItem(AppSettings.TEMP_CONTACTFORM_ID, results.general[0].id);
      this.contactFormService.dataLookupEhr.next(event);
      sessionStorage.setItem('sendDataToDraft', 'true');
      if(!this.isEditContact) {
        this.router.navigate(['/contact-form/add-contact-form']);
      }
      this.ref.close();
    }, (error) => {
    });
  }

  manualAddContactForm() {
    const dataToSend = {
      userId: '',
      ehrUserId: '',
      firstName: this.ngForm.controls.firstName.value,
      lastName: this.ngForm.controls.lastName.value,
      gender: this.ngForm.controls.gender.value,
      email: this.ngForm.controls.email.value,
      dateOfBirthStr: this.formatDate(this.ngForm.controls.dateOfBirth.value),
      addressLineOne: this.ngForm.controls.addressLineOne.value,
      city: this.ngForm.controls.city.value,
      state: this.ngForm.controls.state.value,
      country: this.ngForm.controls.country.value,
      zipCode: this.ngForm.controls.zipCode.value,
      contactNumber: this.ngForm.controls.contactNumber.value,
      identifier: ''
    };
    this.contactFormService.isContactFormPage = true;
    this.contactFormService.getContactFormId().subscribe(results => {
      this.storageService.setItem(AppSettings.TEMP_CONTACTFORM_ID, results.general[0].id);
      this.ref.close();
      sessionStorage.setItem('sendDataToDraft', 'true');
      this.contactFormService.dataLookupEhr.next(dataToSend);
      this.router.navigate(['/contact-form/add-contact-form']);
    }, (error) => {
    });
  }

  onSelectionChange() {
    const ssnControl = this.ngForm.get('ssn');
    const mrnControl = this.ngForm.get('mrn');
    const firstNameControl = this.ngForm.get('firstName');
    const lastNameControl = this.ngForm.get('lastName');
    const genderControl = this.ngForm.get('gender');
    const dateOfBirthControl = this.ngForm.get('dateOfBirth');
    const phoneNumControl = this.ngForm.get('contactNumber');
    const emailControl = this.ngForm.get('email');
    const addressLineOneControl = this.ngForm.get('addressLineOne');
    const stateControl = this.ngForm.get('state');
    const cityControl = this.ngForm.get('city');
    const zipCodeControl = this.ngForm.get('zipCode');
    const countryControl = this.ngForm.get('country');
    this.showResult = false;
    this.lookupData = [];
    if (this.ngForm.controls.radioValue.value === 'SSN') {
      ssnControl.setValidators([Validators.required]);
      ssnControl.markAsUntouched();
      ssnControl.updateValueAndValidity();
      mrnControl.setValue('');
      mrnControl.updateValueAndValidity();
      mrnControl.clearValidators();
      mrnControl.updateValueAndValidity();
      mrnControl.markAsUntouched();
      mrnControl.updateValueAndValidity();
      mrnControl.markAsPristine();
      mrnControl.updateValueAndValidity();
      firstNameControl.setValue('');
      firstNameControl.clearValidators();
      firstNameControl.updateValueAndValidity();
      firstNameControl.markAsUntouched();
      firstNameControl.updateValueAndValidity();
      firstNameControl.markAsPristine();
      firstNameControl.updateValueAndValidity();
      lastNameControl.setValue('');
      lastNameControl.clearValidators();
      lastNameControl.updateValueAndValidity();
      lastNameControl.markAsUntouched();
      lastNameControl.updateValueAndValidity();
      lastNameControl.markAsPristine();
      lastNameControl.updateValueAndValidity();
      genderControl.setValue('');
      genderControl.clearValidators();
      genderControl.updateValueAndValidity();
      genderControl.markAsUntouched();
      genderControl.updateValueAndValidity();
      genderControl.markAsPristine();
      genderControl.updateValueAndValidity();
      dateOfBirthControl.setValue('');
      dateOfBirthControl.clearValidators();
      dateOfBirthControl.updateValueAndValidity();
      dateOfBirthControl.markAsUntouched();
      dateOfBirthControl.updateValueAndValidity();
      phoneNumControl.setValue('');
      phoneNumControl.clearValidators();
      phoneNumControl.updateValueAndValidity();
      phoneNumControl.markAsUntouched();
      phoneNumControl.updateValueAndValidity();
      emailControl.setValue('');
      emailControl.clearValidators();
      emailControl.updateValueAndValidity();
      emailControl.markAsUntouched();
      emailControl.updateValueAndValidity();
      addressLineOneControl.setValue('');
      addressLineOneControl.clearValidators();
      addressLineOneControl.updateValueAndValidity();
      addressLineOneControl.markAsUntouched();
      addressLineOneControl.updateValueAndValidity();
      stateControl.setValue('');
      stateControl.clearValidators();
      stateControl.updateValueAndValidity();
      stateControl.markAsUntouched();
      stateControl.updateValueAndValidity();
      cityControl.setValue('');
      cityControl.clearValidators();
      cityControl.updateValueAndValidity();
      cityControl.markAsUntouched();
      cityControl.updateValueAndValidity();
      zipCodeControl.setValue('');
      zipCodeControl.clearValidators();
      zipCodeControl.updateValueAndValidity();
      zipCodeControl.markAsUntouched();
      zipCodeControl.updateValueAndValidity();
      countryControl.setValue('');
      countryControl.clearValidators();
      countryControl.updateValueAndValidity();
      countryControl.markAsUntouched();
      countryControl.updateValueAndValidity();
    }
    else if (this.ngForm.controls.radioValue.value === 'HCMRN') {
      ssnControl.setValue('');
      ssnControl.updateValueAndValidity();
      ssnControl.clearValidators();
      ssnControl.updateValueAndValidity();
      ssnControl.markAsUntouched();
      ssnControl.markAsPristine();
      ssnControl.updateValueAndValidity();
      mrnControl.setValidators([Validators.required]);
      mrnControl.updateValueAndValidity();
      mrnControl.markAsUntouched();
      mrnControl.updateValueAndValidity();

      firstNameControl.setValue('');
      firstNameControl.clearValidators();
      firstNameControl.updateValueAndValidity();
      firstNameControl.markAsUntouched();
      firstNameControl.markAsPristine();
      firstNameControl.updateValueAndValidity();
      lastNameControl.setValue('');
      lastNameControl.clearValidators();

      lastNameControl.markAsUntouched();
      lastNameControl.markAsPristine();
      lastNameControl.updateValueAndValidity();
      genderControl.setValue('');
      genderControl.clearValidators();
      genderControl.updateValueAndValidity();
      genderControl.markAsUntouched();
      genderControl.markAsPristine();
      genderControl.updateValueAndValidity();
      dateOfBirthControl.setValue('');
      dateOfBirthControl.clearValidators();
      dateOfBirthControl.updateValueAndValidity();
      dateOfBirthControl.markAsUntouched();
      dateOfBirthControl.updateValueAndValidity();
      phoneNumControl.setValue('');
      phoneNumControl.clearValidators();
      phoneNumControl.updateValueAndValidity();
      phoneNumControl.markAsUntouched();
      phoneNumControl.updateValueAndValidity();
      emailControl.setValue('');
      emailControl.clearValidators();
      emailControl.updateValueAndValidity();
      emailControl.markAsUntouched();
      emailControl.updateValueAndValidity();
      addressLineOneControl.setValue('');
      addressLineOneControl.clearValidators();
      addressLineOneControl.updateValueAndValidity();
      addressLineOneControl.markAsUntouched();
      addressLineOneControl.updateValueAndValidity();
      stateControl.setValue('');
      stateControl.clearValidators();
      stateControl.updateValueAndValidity();
      stateControl.markAsUntouched();
      stateControl.updateValueAndValidity();
      cityControl.setValue('');
      cityControl.clearValidators();
      cityControl.updateValueAndValidity();
      cityControl.markAsUntouched();
      cityControl.updateValueAndValidity
      zipCodeControl.setValue('');
      zipCodeControl.clearValidators();
      zipCodeControl.updateValueAndValidity();
      zipCodeControl.markAsUntouched();
      zipCodeControl.updateValueAndValidity();
      countryControl.setValue('');
      countryControl.clearValidators();
      countryControl.updateValueAndValidity();
      countryControl.markAsUntouched();
      countryControl.updateValueAndValidity();
    }
    else {
      ssnControl.setValue('');
      ssnControl.updateValueAndValidity();
      ssnControl.clearValidators();
      ssnControl.updateValueAndValidity();
      ssnControl.markAsUntouched();
      ssnControl.updateValueAndValidity();
      ssnControl.markAsPristine();
      ssnControl.updateValueAndValidity();
      mrnControl.setValue('');
      mrnControl.updateValueAndValidity();
      mrnControl.clearValidators();
      mrnControl.updateValueAndValidity();
      mrnControl.markAsUntouched();
      mrnControl.updateValueAndValidity();
      mrnControl.markAsPristine();
      mrnControl.updateValueAndValidity();
      firstNameControl.setValidators([Validators.required, Validators.pattern(AppSettings.ALPHA_NUMERIC_PATTERN_NAME_MIN),
      Validators.minLength(1), Validators.maxLength(20)
      ]);
      firstNameControl.updateValueAndValidity();
      lastNameControl.setValidators([Validators.required, Validators.pattern(AppSettings.ALPHA_NUMERIC_PATTERN_NAME_MIN),
      Validators.minLength(1), Validators.maxLength(20)
      ]);
      lastNameControl.updateValueAndValidity();
      phoneNumControl.setValidators([Validators.maxLength(12), Validators.minLength(12)]);
      emailControl.setValidators([Validators.pattern(AppSettings.EMAIL_PATTERN)]);
      zipCodeControl.setValidators([Validators.pattern(AppSettings.ALPHA_NUMERIC), Validators.minLength(5), Validators.maxLength(6),]);
      zipCodeControl.updateValueAndValidity();
    }
  }

  setDropdownValues(tagsData) {
    this.genderList = [];
    this.genderList.push({ label: this.commonService.getLabel('Select'), value: '' });
    tagsData.gender.forEach(element => {
      this.genderList.push({ label: element.tagValue, value: element.id });
    });
  }

  close() {
    this.ref.close();
  }

  getFormattedDate(dateOfBirth) {
    return moment.tz(new Date(dateOfBirth), "GMT").format("MM/DD/YYYY");
  }
}
